<template>
    <div>
      <qrcode-stream @decode="onDecode" @init="onInit" @error="onError" />
      <div v-if="decodedResult">
        Decoded Result: {{ decodedResult }}
      </div>
    </div>
  </template>
  
  <script>
  import { QrcodeStream } from 'vue3-qrcode-reader';
  
  export default {
    components: {
      QrcodeStream
    },
    data() {
      return {
        decodedResult: null
      };
    },
    methods: {
      onDecode(decodedString) {
        this.decodedResult = decodedString;
      },
      onInit(promise) {
        promise
          .then(() => {
            console.log('QR code reader initialized');
          })
          .catch(error => {
            console.error('QR code reader initialization failed', error);
          });
      },
      onError(error) {
        console.error('Error reading QR code', error);
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any custom styles if needed */
  </style>
  