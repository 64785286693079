<template>

    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class=" login-card  card login-dark">
                    <div  >
                        <div class="card-header"  >
                            <h4>Vehicle Details </h4>
                            <span v-if="request.approval_status==0" class="badge badge-pill badge-warning text-white">
                                                                    Pending
                                                            </span>
                                                            <span v-else-if="request.approval_status==1" class="badge badge-pill badge-success text-white">
                                                                    Accepted
                                                            </span>
                                                            <span v-else-if="request.approval_status==2" class="badge badge-pill badge-danger text-white">
                                                                    Denied
                                                            </span>
                        </div>

                        <div class="card-body bg-white">
 
                            <div class="col-12 row">
                                <div class="col-12 "> 
                                    <canvas ref="qrcodeCanvas"></canvas>
                                </div>
                                <div class="col-12 ">
                                        <b>Code:</b>  {{ request.empcode }}<br> 
                                        <b>Name:</b>  {{ request.empname }}<br>
                                        <b>Phone No.:</b>  {{ request.contact }}<br>
                                        <b>Department:</b>  {{ request.dept_name }}<br> 
                                </div>
                                 
                            </div> 
                            <table>
                                    <tr class="bottomborder">
                                        <td><b>Purpose</b></td>
                                        <td>{{ request.purpose }}</td>
                                    </tr>
                                    <tr class="bottomborder">
                                        <td><b>Source</b></td>
                                        <td>{{ request.source }}</td>
                                    </tr>
                                    <tr   class="bottomborder">
                                        <td><b>Destination</b></td>
                                        <td>{{ request.destination }}</td>
                                    </tr> 
                                    <tr   class="bottomborder">
                                        <td><b>Check Out</b></td>
                                        <td>{{ request.schedule_checkout_date + '   -  '+request.schedule_checkout_time }}</td>
                                    </tr> 
                                    <tr   class="bottomborder">
                                        <td><b>Check In</b></td>
                                        <td>{{ request.schedule_checkin_date + '   -  '+request.schedule_checkin_time }}</td>
                                    </tr> 
                                </table>
                               
                        </div>
                        <div class="card-footer bg-white"  >
                          
                        </div> 
                    </div>
                    
                </div>
            </div>
        </div>
    </div>





</template>
<style scoped>
.page-body {
    margin-left: 0 !important;
    margin-top: 0px;
}

.user-profile .hovercard .cardheader {
    height: 100px;
    background: linear-gradient(45deg, #0437c8, #007611);
}

@media only screen and (max-width: 575.98px) {
    .tabs-scoial li:nth-child(3) {
        position: sticky;
    }
}
.avatar img{
    width: 100%; 
}
.login-card {
    min-height: 80vh;
}
</style>

<script>
import axios from 'axios';
import QRCode from 'qrcode';

export default {
    name: 'VehicleRequestView',
    components: {

    },
    mounted() {
        if (this.$route.query.q) {
                this.getBasics(this.$route.query.q);
            }else
            window.location.href = this.$cur_host+'login';
    },
    data() {
        return {  
            request: { "empname": '', "empname": '',"contact": '', "purpose": '','dept_name':'','source':'','destination':'','schedule_checkout_date':'','schedule_checkout_time':'','schedule_checkin_date':'','schedule_checkin_time':'','approval_status':'' },
            status:''
        }
    },
    methods: {  
        getBasics(token) {
            var self = this;
            axios.post('getvehicalrequest',{token:token}).then(function (response) {
                
                   self.request= response.data.request;
                   self.getQrCode(self.request.token);
                window.scrollTo(0, 0);
            });
        },
        async getQrCode(token) {
            try {
              if(token.length>0){
                  const canvas = this.$refs.qrcodeCanvas;
                 var url=this.$api_host+'vehiclepassview?q='+token;
                   //var url='http://192.168.1.8:8081/vehiclepassview?q='+token;
                  await QRCode.toCanvas(canvas,url , { width: 200 });
                  $("#qrcodeView").modal("toggle");
              }
              
            } catch (error) {
              console.error(error);
            }
          },

}
}
</script>
