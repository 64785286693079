<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Employee Gatepass" />
        <div class="container-fluid">

            <div class="col-xxl-12">
                <div class="card">
                    <div class="card-header">
                        <button class="btn btn-secondary" type="button" data-bs-toggle="modal"
                            data-original-title="test" data-bs-target="#EmployeegatepassAdd">Create Gatepass</button>
                    </div>
                    <div class="card-body">
                        <ul class="nav nav-tabs border-tab border-0 mb-0 nav-danger" id="topline-tab" role="tablist">
                            <li class="nav-item"><a class="nav-link active nav-border pt-0 txt-danger nav-danger"
                                    id="all-gatepass-tab" data-bs-toggle="tab" href="#all-gatepass" role="tab"
                                    aria-controls="all-gatepass" aria-selected="true"><i
                                        class="icofont icofont-man-in-glasses"></i>All</a></li>
                            <li class="nav-item"><a class="nav-link nav-border txt-danger nav-danger" id="requested-tab"
                                    data-bs-toggle="tab" href="#requested" role="tab" aria-controls="requested"
                                    aria-selected="false"><i class="icofont icofont-file-document"></i>Requested</a>
                            </li>
                            <li class="nav-item"><a class="nav-link nav-border txt-danger nav-danger"
                                    id="completed-gatepass-tab" data-bs-toggle="tab" href="#completed-gatepass"
                                    role="tab" aria-controls="completed-gatepass" aria-selected="false"><i
                                        class="icofont icofont-star"></i>Completed</a></li>
                        </ul>
                        <div class="tab-content" id="topline-tabContent">
                            <div class="tab-pane fade show active" id="all-gatepass" role="tabpanel"
                                aria-labelledby="all-gatepass-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">All Gate Pass:</h6>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th> 
                                                        <th>Employee Name</th>
                                                        <th>Department</th>
                                                        <th>Contact</th>
                                                        <th>Purpose</th> 
                                                        <th>Check Out</th>
                                                        <th>Check In</th>
                                                        <th>Approval Statue</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in gatepass" :key="item.gatepassid">
                                                        <td>{{ item.schedule_date }}</td>
                                                       
                                                        <td>{{ item.empname }}  </td>
                                                        <td>{{ item.dept_name }} </td>
                                                        <td>{{ item.contact }}</td>
                                                        <td>{{ item.purpose }}  </td>
                                                         
                                                        <td>{{ item.emp_chekout }}</td>
                                                        <td>{{ item.emp_chekin }}</td>
                                                        <td> <div v-if="item.approval_status==0" class="badge bg-warning">Pending</div>
                                                            <div v-else-if="item.approval_status==1" class="badge bg-success">Accepted</div>
                                                            <div v-else-if="item.approval_status==2" class="badge bg-danger">Denied</div></td>
                                                        <td>
                                                            <ul class="action">
                                                              
                                                                <li class="link" @click="getQrCode(item.token)"><i
                                                                        class="icofont icofont-qr-code"></i></li> 
                                                                <li class="delete"
                                                                    @click="removeGatepass(item.gatepassid)"><i
                                                                        class="icon-trash"></i></li>
                                                               
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="requested" role="tabpanel" aria-labelledby="requested-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">Pending For Approval:</h6>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th> 
                                                        <th>Employee Name</th>
                                                        <th>Department</th>
                                                        <th>Contact</th>
                                                        <th>Purpose</th> 
                                                        <th>Check Out</th>
                                                        <th>Check In</th>
                                                        <th>Approval</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in requested_pass" :key="item.gatepassid">
                                                        <td>{{ item.schedule_date }}</td>
                                                       
                                                        <td>{{ item.empname }}  </td>
                                                        <td>{{ item.dept_name }} </td>
                                                        <td>{{ item.contact }}</td>
                                                        <td>{{ item.purpose }}  </td>
                                                         
                                                        <td>{{ item.emp_chekout }}</td>
                                                        <td>{{ item.emp_chekin }}</td>
                                                        <td> <div v-if="item.approval_status==0" class="badge bg-warning">Pending</div>
                                                            <div v-else-if="item.approval_status==1" class="badge bg-success">Accepted</div>
                                                            <div v-else-if="item.approval_status==2" class="badge bg-danger">Denied</div></td>
                                                        <td>
                                                            
                                                            <ul class="action">
                                                                <li class="delete"
                                                                    @click="removeGatepass(item.gatepassid)"><i
                                                                        class="icon-trash"></i></li>
                                                                <li v-if="user.emprole<3" title="Approve" class="link" @click="accept(item)">
                                                                    <i class="icon-check text-success"></i></li>
                                                                &nbsp;&nbsp;&nbsp;
                                                                <li  v-if="user.emprole<3" title="Decline" @click="reject(item)"><i
                                                                        class="icon-close text-danger"></i></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="completed-gatepass" role="tabpanel"
                                aria-labelledby="completed-gatepass-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">Completed Gatepass:</h6>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th> 
                                                        <th>Employee Name</th>
                                                        <th>Department</th>
                                                        <th>Contact</th>
                                                        <th>Purpose</th> 
                                                        <th>Check Out</th>
                                                        <th>Check In</th>
                                                        <th>Exact Check Out</th>
                                                        <th>Exact Check In</th>
                                                        <th>Approval</th> 
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in completed_pass" :key="item.gatepassid">
                                                        <td>{{ item.schedule_date }}</td>
                                                       
                                                        <td>{{ item.empname }}  </td>
                                                        <td>{{ item.dept_name }} </td>
                                                        <td>{{ item.contact }}</td>
                                                        <td>{{ item.purpose }}  </td>
                                                         
                                                        <td>{{ item.emp_chekout }}</td>
                                                        <td>{{ item.emp_chekin }}</td>
                                                        <td>{{ item.check_out }}</td>
                                                        <td>{{ item.check_in }}</td>
                                                        <td> <div v-if="item.approval_status==0" class="badge bg-warning">Pending</div>
                                                            <div v-else-if="item.approval_status==1" class="badge bg-success">Accepted</div>
                                                            <div v-else-if="item.approval_status==2" class="badge bg-danger">Denied</div></td>
                                                       
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     
    <div class="modal fade" id="qrcodeView" tabindex="-1" role="dialog" aria-labelledby="qrcodeView"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">QR Code</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                          <canvas ref="qrcodeCanvas"></canvas>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalsPart v-if="!['login'].includes($route.name)" @getGateEmpPassData="updateData" />
</template>
<style scoped>
.avatar {
    width: 50px;
    border-radius: 30px;
}
</style>
<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import GatePass from '@/components/GatepassPrint.vue';
import axios from 'axios';
import QRCode from 'qrcode';

export default {
    name: 'CreateEmployeeGatepass',
    components: {
        PageTitle, ModalsPart,GatePass
    }, mounted() {
        this.getBasics();

    }, data() {
        return {
            gatepass: [],
            requested_pass: [],
            completed_pass: [],
            rooms:[],
            room_booking_visitor:[],
            selected_room:{'roomid':0,'gatepassid':0,'schedule_date':'','schedule_time':''},
            user:[]
        }
    },
    methods: {
            
        updateData() {
            this.getBasics();
        },
        getBasics() {
            var self = this;
            axios.post('getEmpGatepass').then(function (response) {
                self.gatepass = response.data.gatepass;
                self.completed_pass = response.data.completed_pass;
                self.requested_pass = response.data.requested_pass;
                self.rooms = response.data.rooms;
                self.user = response.data.user;
                window.scrollTo(0, 0);
            });
        },
        removeGatepass(id) {
            var self = this;
            self.$swal({
                title: "Do You Want To Delete?",
                text: "Are you sure? You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('removeEmpGatepass', { gatepass_id: id }).then(function (response) {
                        self.gatepass = response.data.gatepass;
                        window.scrollTo(0, 0);
                    });
                }
            });

        },
        accept(visit) {
            var self = this;
            self.$swal({
                title: "Do You Want To Accept?",
                text: "Just For Confirmation !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('acceptEmpGatepass', { gatepass_id: visit.gatepassid }).then(function (response) {
                self.gatepass = response.data.gatepass;
                self.completed_pass = response.data.completed_pass;
                self.requested_pass = response.data.requested_pass;
                        window.scrollTo(0, 0);
                    });
                }
            });

        },
        reject(visit) {
            var self = this;
            self.$swal({
                title: "Do You Want To Decline?",
                text: "Just For Confirmation !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('declineEmpGatepass', { gatepass_id: visit.gatepassid }).then(function (response) {
                self.gatepass = response.data.gatepass;
                self.completed_pass = response.data.completed_pass;
                self.requested_pass = response.data.requested_pass;
                        window.scrollTo(0, 0);
                    });
                }
            });

        },
        async getQrCode(token) {
            try {
              if(token.length>0){
                  const canvas = this.$refs.qrcodeCanvas;
                  var url=this.$api_host+'employeegatepassview?q='+token;
                  await QRCode.toCanvas(canvas,url , { width: 200 });
                  $("#qrcodeView").modal("toggle");
              }
              
            } catch (error) {
              console.error(error);
            }
          },
        getImageUrl(image) {
            return `${this.$hostname}/writable/visitors/${image}`;
        }
    }
}
</script>
