<template>

    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class="login-card login-dark">
                    <div>
                       
                        <div class="login-main"  >

                            <h4>Fill Your Details </h4>
                            
                            <div class="form-group">
                                <label class="col-form-label">Your Name</label>
                                <input class="form-control" type="text" v-model="name"
                                    placeholder="Type Here..." required="">
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Your Firm Name</label>
                                <input class="form-control" type="text" v-model="firmname"
                                    placeholder="Type Here..." required="">
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Your Address</label>
                                <input class="form-control" type="text" v-model="address"
                                    placeholder="Type Here..." required="">
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Your Designation</label>
                                <input class="form-control" type="text" v-model="designation"
                                    placeholder="Type Here..." required="">
                            </div>

                            <div class="text-end mt-3">
                                <button class="btn btn-primary btn-block w-100" @click="saveData">Submit</button>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>





</template>
<style scoped>
.page-body {
    margin-left: 0 !important;
    margin-top: 0px;
}

.user-profile .hovercard .cardheader {
    height: 100px;
    background: linear-gradient(45deg, #0437c8, #007611);
}

@media only screen and (max-width: 575.98px) {
    .tabs-scoial li:nth-child(3) {
        position: sticky;
    }
}
</style>

<script>
import axios from 'axios';

export default {
    name: 'VisitorProfile',
    components: {

    },
    mounted() {
        
    },
    data() {
        return {
            name: '',
            firmname: '',
            address: '',
            designation:''
        }
    },
    methods: {  
    saveData() {
        var self = this;
        if (self.name.length == 0)
        self.$swal("Please Enter Name");
        else if (self.firmname.length == 0)
        self.$swal("Please Enter Firm Name");
        else if (self.address.length == 0)
        self.$swal("Please Enter Address");
        else
        {
            axios.post('saveProfile', { name: self.name ,address: self.address ,designation: self.designation ,firmname: self.firmname ,token: localStorage['token'] }).then(function (response) {
                if(response.data.is==1){ 
                    self.$swal({
                            title: "Successfull",
                            text: "Please Select Option From Bellow",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonText: "Profile",
                            confirmButtonText: "Create Gatepass"
                        }).then((result) => { // <--
                            if (result.value) { // <-- if confirmed
                                self.$router.push({ name: "visitorcreategatepass" });  
                            }else
                                self.$router.push({ name: "visitorpage" });  
                        });
                        //self.$router.push({ name: "visitorpage" });  
                }else
                self.$swal("Please Try Again");

                window.scrollTo(0, 0);
            });
        }
    },

}
}
</script>
