<template>
    <div id="">
      <QRCodeReader />
    </div>
  </template>
  
  <script>
  import QRCodeReader from '@/components/QRCodeReader.vue'
  
  export default { 
    components: {
      QRCodeReader
    }
  }
  </script>
  
  <style>
  /* Add any global styles if needed */
  </style>
  