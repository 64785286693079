<template>

    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class=" login-card  card login-dark">
                    <div  >
                        <div class="card-header"  >
                            <h4>Gatepass Details </h4>
                                                            
                                                            <span v-if="gatepass.check_out!=null" class="badge badge-pill badge-success text-white">
                                                                    Checked Out
                                                            </span>
                                                            <span v-else-if="gatepass.checkout_approval==1" class="badge badge-pill badge-warning text-white">
                                                                    Check Out Approved
                                                            </span>
                                                            <span v-else-if="gatepass.checkout_approval==2" class="badge badge-pill badge-warning text-white">
                                                                    Check Out Denied
                                                            </span>
                                                            <span v-else-if="gatepass.check_in!=null" class="badge badge-pill badge-info text-white">
                                                                    Checked In
                                                            </span>
                                                            <span v-else-if="gatepass.approval_status==0" class="badge badge-pill badge-warning text-white">
                                                                    Pending
                                                            </span>
                                                            <span v-else-if="gatepass.approval_status==1" class="badge badge-pill badge-success text-white">
                                                                    Accepted
                                                            </span>
                                                            <span v-else-if="gatepass.approval_status==2" class="badge badge-pill badge-danger text-white">
                                                                    Denied
                                                            </span>
                        </div>

                        <div class="card-body bg-white">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <canvas ref="qrcodeCanvas"></canvas>
                                </div>
                                <div class="col-4 ">
                                    <div class="avatar"><img alt="" style="width:100px;"  :src="this.$hostname+'/writable/visitors/'+gatepass.photo" v-if="gatepass.photo"></div>
                                </div>
                                <div class="col-8 ">
                                        <b>Name :</b>  {{ gatepass.name }}<br>
                                        <b>Phone No :</b>  {{ gatepass.contact }}<br>
                                        <b>Firm Name :</b>  {{ gatepass.firmname }}<br> 
                                        <b>Address :</b>  {{ gatepass.address }}<br> 
                                </div>
                            </div>
                            <div class="col-12 mt-4 row"> 
                                <table>
                                    <tr class="bottomborder">
                                        <td><b>Purpose</b></td>
                                        <td>{{ gatepass.purpose }}</td>
                                    </tr>
                                    <tr class="bottomborder">
                                        <td><b>Schedule Date</b></td>
                                        <td>{{ gatepass.schedule_date }}</td>
                                    </tr>
                                    <tr v-if="gatepass.meeting_room" class="bottomborder">
                                        <td><b>Meeting Schedule</b></td>
                                        <td>{{ gatepass.meeting_room }}</td>
                                    </tr> 
                                </table>
                             
                                   
                            </div>
                            <div class="form-group col-12 mt-4 ">
                              <lable class="col-3"> Visitor Sign</lable> &nbsp;&nbsp;&nbsp;&nbsp;
                              <lable class="col-3"> Employee Sign</lable>&nbsp;&nbsp;&nbsp;&nbsp;
                              <lable class="col-3"> Security Sign</lable>
                            </div> 
                        <div class="card-footer bg-white" v-if="scan_user && scan_user.empdept==3" >
                            <button v-if="gatepass.check_in==null" class="btn btn-primary btn-sm" @click="mark_checkin(gatepass.visitid,1)">Check In</button>
                            <button v-if="gatepass.check_out==null" class="btn btn-primary btn-sm" @click="mark_checkin(gatepass.visitid,2)">Check Out</button>
                        </div> 
                            </div> 
                    </div>
                    
                </div>
            </div>
        </div>
    </div>





</template>
<style scoped>
.bottomborder{
    border-bottom: 1px solid grey;
}
.page-body {
    margin-left: 0 !important;
    margin-top: 0px;
}

.user-profile .hovercard .cardheader {
    height: 100px;
    background: linear-gradient(45deg, #0437c8, #007611);
}

@media only screen and (max-width: 575.98px) {
    .tabs-scoial li:nth-child(3) {
        position: sticky;
    }
}
.avatar img{
    width: 100%; 
}
.login-card {
    min-height: 80vh;
}
</style>

<script>
import axios from 'axios';
import QRCode from 'qrcode';

export default {
    name: 'VisitorGatepassView',
    components: {

    },
    mounted() {
        if (this.$route.query.q) {
                this.getBasics(this.$route.query.q);
            }else
            window.location.href = this.$cur_host+'register';
    },
    data() {
        return {  
            gatepass: { "name": '', "firmname": '',"contact": '', "address": '',"purpose": '','designation':'','schedule_date':'','photo':'' } ,
            status:'',
             scan_user:''
        }
    },
    methods: { 
        
        mark_checkin(passid,what) {
            var self = this;
            self.$swal({
                title: "Do You Want To Check In?",
                text: "Just For Confirmation !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('qrcheckInUser', { passid: passid,what:what }).then(function (response) {
                        if(response.data.is==1){
                            self.gatepass= response.data.gatepass; 
                        }
                            self.$swal(response.data.gatepassReq);
                    });
                }
            });

        },
        getBasics(token) {
            var self = this;
            axios.post('getVisitorpass',{token:token}).then(function (response) {
                   self.gatepass= response.data.gatepass; 
                   self.scan_user= response.data.scan_user; 
                   self.getQrCode(self.gatepass.token);
                window.scrollTo(0, 0);
            });
        },
        async getQrCode(token) {
            try {
              if(token.length>0){
                  const canvas = this.$refs.qrcodeCanvas;
                  var url=this.$api_host+'vp?q='+token;
                  await QRCode.toCanvas(canvas,url , { width: 200 });
                  $("#qrcodeView").modal("toggle");
              }
              
            } catch (error) {
              console.error(error);
            }
          },

}
}
</script>
