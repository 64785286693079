<template>
    <div>
      <div ref="printSection" class="gate-pass">
        <div class="card">
          <div class="card-header">
              <h2>HD Hyundai Gatepass</h2>
              <span v-if="gatepass.approval_status==0" class="badge badge-pill badge-warning text-white">
                                                                    Pending
                                                            </span>
                                                            <span v-else-if="gatepass.approval_status==1" class="badge badge-pill badge-success text-white">
                                                                    Accepted
                                                            </span>
                                                            <span v-else-if="gatepass.approval_status==2" class="badge badge-pill badge-danger text-white">
                                                                    Denied
                                                            </span>
          </div>
          <div class="card-body">
              <div class="col-12 row">
                <div class="col-12 row">
                                <div class="col-4 ">
                                    <div class="avatar"><img alt="" style="width:100px;"  :src="this.$hostname+'/writable/visitors/'+gatepass.photo" v-if="gatepass.photo"></div>
                                </div>
                                <div class="col-8 ">
                                        <b>Name :</b>  {{ gatepass.name }}<br>
                                        <b>Phone No :</b>  {{ gatepass.contact }}<br>
                                        <b>Firm Name :</b>  {{ gatepass.firmname }}<br> 
                                        <b>Address :</b>  {{ gatepass.address }}<br> 
                                </div>
                            </div> 
                            <div class="col-12 mt-4 row"> 
                              <table>
                                    <tr class="bottomborder">
                                        <td><b>Purpose</b></td>
                                        <td>{{ gatepass.purpose }}</td>
                                    </tr>
                                    <tr class="bottomborder">
                                        <td><b>Schedule Date</b></td>
                                        <td>{{ gatepass.schedule_date }}</td>
                                    </tr>
                                    <tr v-if="gatepass.meeting_room" class="bottomborder">
                                        <td><b>Meeting Schedule</b></td>
                                        <td>{{ gatepass.meeting_room }}</td>
                                    </tr> 
                                </table>
                               
                                   
                            </div>
                            <div class="form-group col-12 mt-4 ">
                              <lable class="col-3"> Visitor Sign</lable> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <lable class="col-3"> Employee Sign</lable>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <lable class="col-3"> Security Sign</lable>
                            </div>
                            
                           

              </div>
          </div>

        </div>
        
      </div> 
    </div>
      <button class="btn btn-danger btn-pill" @click="printGatePass">Print Gate Pass</button>
</template>
  

  <script>
  export default {
    name: 'GatepassPrint',
    props: {
        gatepass:Object
    },
    mounted(){
       
    },
    data() {
       
        },
    methods: {
      generateGatePass() {
        this.date = new Date().toLocaleString();
        this.code = 'GP-' + Date.now();
      },
      printGatePass() {
        const printContents = this.$refs.printSection.innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
      }
    },
    getImageUrl() {
                return `${this.$hostname}/writable/visitors/${gatepass.photo}`;
    } 
  };
  </script>
  
  <style scoped>
  .bottomborder{
      border-bottom: 1px solid grey;
  }
  .gate-pass {
    
    
  }
  .avatar img{
    width: 100%; 
}
  </style>
  