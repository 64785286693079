<template>

  <!-- Page Sidebar Ends-->
  <div class="page-body">

    <PageTitle title="Dashboard" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row size-column">

        <div class="col-xxl-9 box-col-12">
          <div class="row">
            <div class="col-xl-3 col-sm-6">
              <router-link to="gatepass">
                <div class="card o-hidden small-widget">
                  <div class="card-body total-project border-b-primary border-2"><span
                      class="f-light f-w-500 f-14">Today Schedule</span>
                    <div class="project-details">
                      <div class="project-counter">
                        <h2 class="f-w-600">{{ today_gatepasses.length }}</h2>
                      </div>
                      <div class="product-sub bg-primary-light">

                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-3 col-sm-6">
              <router-link to="gatepass">
                <div class="card o-hidden small-widget">
                  <div class="card-body total-Progress border-b-warning border-2"> <span
                      class="f-light f-w-500 f-14">Today Check-In</span>
                    <div class="project-details">
                      <div class="project-counter">
                        <h2 class="f-w-600">{{ today_gatepasses_active.length }}</h2>
                      </div>
                      <div class="product-sub bg-warning-light">

                      </div>
                    </div>

                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-3 col-sm-6">
              <router-link to="gatepass">
                <div class="card o-hidden small-widget">
                  <div class="card-body total-Complete border-b-secondary border-2"><span
                      class="f-light f-w-500 f-14">Today Check-Out</span>
                    <div class="project-details">
                      <div class="project-counter">
                        <h2 class="f-w-600">{{ today_gatepasses_done.length }}</h2>
                      </div>
                      <div class="product-sub bg-secondary-light">

                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-3 col-sm-6">
              <router-link to="vehicalrequest">
                <div class="card o-hidden small-widget">
                  <div class="card-body total-upcoming"><span class="f-light f-w-500 f-14">Today Vehicle-Req</span>
                    <div class="project-details">
                      <div class="project-counter">
                        <h2 class="f-w-600">{{ today_veh_req.length }}</h2>
                      </div>
                      <div class="product-sub bg-light-light">

                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-xxl-9 box-col-12 card">
         <div class="card-header">
            <h2 class="card-title">Department Meeting Schedule</h2>
         </div>
          <div class="card-body row">

            <div class="col-12 col-md-4" v-for="(item, index)  in all_reservations" :key="item.gatepassid">


              <div class="accordion-item">
              <h2 class="accordion-header" :id="index+'_acc'">
                <button class="accordion-button collapsed accordion-light-primary txt-primary" type="button"
                  data-bs-toggle="collapse" :data-bs-target="'#'+'collapse_'+index" aria-expanded="false"
                  aria-controls="collapseOne"> {{ item.date }}<i class="svg-color"
                    data-feather="chevron-down"></i></button>
              </h2>
              <div class="accordion-collapse collapse " :id="'collapse_'+index" :aria-labelledby="index+'_acc'"
                data-bs-parent="#simpleaccordion">
                <div class="accordion-body">
                  <div v-for="(row, index)  in item.schedule" :class="(row.status == 2) ? 'meet_success' : 'meet_pending'">
                  <div><b>{{ index + 1 }}. {{ row.emp_name }}</b> <span class="badge badge-info">{{ row.shedule_time }}</span>
                  </div>
                  <div>Purpose : {{ row.purpose }} <span class="badge badge-warning">{{ row.room_details }}</span></div>
                  <hr>
                </div>
                </div>
              </div>
            </div>


             
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>



</template>
<style scoped>
.meet_success{
  background: #97ff8d;
  padding: 5px;
}.meet_pending{
  background: #f7f7f7;
  padding: 5px;
}
.accordion-item{
  padding: 5px;
}
  </style>
<script>
import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';

export default {
  name: 'HomeView',
  components: {
    PageTitle
  },
  mounted() {
    this.getDashboard();
  },
  data() {
    return {
      emp: [name => '', dept => '', empcode => '', role => ''],
      today_gatepasses: [],
      today_gatepasses_active: [],
      today_gatepasses_done: [],
      today_veh_req: [],
      otp: '',
      all_reservations: []
    }
  },
  methods: {
    getDashboard() {
      var self = this;

      if (localStorage['token']) {
        axios.post('getDashboard', { token: localStorage['token'] }).then(function (response) {
          if (response.data.is == 1) {
            self.emp = response.data.emp;
            self.today_gatepasses = response.data.today_gatepasses;
            self.today_gatepasses_active = response.data.today_gatepasses_active;
            self.today_gatepasses_done = response.data.today_gatepasses_done;
            self.today_veh_req = response.data.today_veh_req;
            self.all_reservations = response.data.all_reservations;
          } else {
          }
        });
      }
    },
  }
}
</script>
