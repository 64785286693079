<template>
  <nav>
    <template   v-if="!['vr','ap','vp','vehiclepassview','visitorgatepassview','employeegatepassview','printpass','login','visitorpage','capture','register','visitorprofile','visitorcreategatepass','approvegatepass','qrcodereader',''].includes($route.name)">
      <component  :is="headerComponent" />
    </template>
  </nav>  
  <component  >
    <router-view/>
  </component>
    <component   :is="footerComponent" />
</template>
<style>
th{
  background: #00007c !important;
  color: white !important;
  padding: 8px 5px !important;
}
@media (max-width:600px)  { 
 #topline-tab{
  DISPLAY: flex;
    overflow-x: scroll;
    overflow-y: hidden;
 }
}
</style>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import FooterPart from '@/components/Footer.vue'; 
import ModalsPart from '@/components/Modals.vue';

export default {
  
  name: 'App',
   
  data() {
    return { 
        header_part: Header,
        footer_part: FooterPart,
       
    };
  },
  computed: {
    headerComponent() {
      return (!['vr','ap','vp','vehiclepassview','visitorgatepassview','employeegatepassview','printpass','login','visitorpage','capture','register','visitorprofile','visitorcreategatepass','approvegatepass','qrcodereader','/'].includes(this.$route.name))&&(this.$route.name)?this.header_part : null;
    },
    footerComponent() {
      return (!['vr','ap','vp','vehiclepassview','visitorgatepassview','employeegatepassview','printpass','login','visitorpage','capture','register','visitorprofile','visitorcreategatepass','approvegatepass','qrcodereader','/'].includes(this.$route.name))&&(this.$route.name)?this.footer_part : null;
    },
  },
}

</script>