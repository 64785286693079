<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Verify Gatepass" />
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            
                        </div>
                        <div class="card-body badge-spacing">
                            <div class="table-responsive custom-scrollbar">
                                <table class="table display" id="basic-1">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Photo</th>
                                            <th>Visitor Name</th>
                                            <th>Firm</th>
                                            <th>Contact</th>
                                            <th>Purpose</th>
                                            <th>Check In</th> 
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  v-for="(item ,index)  in gatepass" :key="item.gatepassid" >
                                            <td>{{item.schedule_date}}</td>
                                            <td>{{item.photo}}</td>
                                            <td>{{item.name}}</td>
                                            <td>{{item.firmname}}</td>
                                            <td>{{item.contact}}</td>
                                            <td>{{item.purpose}}</td>
                                            <td>{{item.check_in}}</td> 
                                            <td>
                                                <ul class="action">
                                                    <li class="edit" @click=""><i class="icon-pencil-alt"></i></li>
                                                    <li class="link" @click="openlink(item)"><i class="icon-link"></i></li>
                                                    <li class="delete" @click="removeGatepass(item.gatepassid)"><i class="icon-trash"></i></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer   start-->

    <ModalsPart v-if="!['login'].includes($route.name)"  @getGatePassData="updateData"/>
</template>

<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import axios from 'axios';
 
export default {
    name: 'CreateGatepass', 
    components: {
        PageTitle, ModalsPart
    }, mounted() {
        this.getBasics();

    }, data() {
        return {
          gatepass: []
        }
    },
    methods: { 
        openlink(item){
            window.open(window.location.origin+"/visitorpage?q="+item.token, '_blank');   
        },
        updateData(value) {
            this.gatepass = value;
        },
        getBasics() {
            var self = this;
            axios.post('getGatepass').then(function (response) {
                self.gatepass = response.data.gatepass; 
                window.scrollTo(0, 0);
            });
        },
        removeGatepass(id) {
            var self = this;
            self.$swal({
                title: "Do You Want To Delete?",
                text: "Are you sure? You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('removeGatepass', {gatepass_id:id}).then(function (response) {
                        self.gatepass = response.data.gatepass; 
                        window.scrollTo(0, 0);
                    });
                }
            });

        }
       
    }
}
</script>
