<!-- src/App.vue -->
<template>
    
      <GatePass :gatepass="gatepass"/>
     
  </template>
  
  <script>
  import GatePass from '@/components/GatepassPrint.vue';
  import axios from 'axios';
  
  export default {
    name: 'GatePassView',
    components: {
      GatePass
    },  data() {
        return {
             gatepass: { "name": '', "firmname": '',"contact": '', "address": '',"purpose": '','designation':'','schedule_date':'' } 
            
        }
    },
    mounted() {
            if (this.$route.query.q) {
                this.getBasics(this.$route.query.q);
            }
        }, 
    methods: {
            getBasics(passid) {
            var self = this;
            axios.post('getGatepassDetails',{passid:passid}).then(function (response) {
                self.gatepass = response.data.gatepass; 
                window.scrollTo(0, 0);
            });
        },
        }
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
  
    
  </style>
  