<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Meeting Rooms" />
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-secondary" type="button" data-bs-toggle="modal"
                                data-original-title="test" data-bs-target="#meetingroomAdd">Add Meeting Rooms</button>
                        </div>
                        <div class="card-body badge-spacing">
                            <div class="table-responsive custom-scrollbar">
                                <table class="table display" id="basic-1">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  v-for="(item ,index)  in rooms" :key="item.id" >
                                            <td>{{item.roomname}}</td>
                                            <td>{{(item.roomstatus==1)?'Active':'De-Active'}}</td>
                                            <td>
                                                <ul class="action">
                                                    <li class="edit" @click="edit_dept(item.id)"><i class="icon-pencil-alt"></i></li>
                                                    <li class="delete" @click="remove_dept(item.id)"><i class="icon-trash"></i></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer   start-->

    <ModalsPart v-if="!['login'].includes($route.name)" @getRoomsData="updateData"/>
</template>

<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import axios from 'axios';
const config = {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: 'json'
         };
export default {
    name: 'Rooms', 
    components: {
        PageTitle, ModalsPart
    }, mounted() {
        this.getBasics();

    }, data() {
        return {
            rooms: []
        }
    },
    methods: {
        updateData(value) {
            this.rooms = value;
        },
        getBasics() {
            var self = this;
            axios.post('getRooms', config).then(function (response) {
                self.rooms = response.data.rooms; 
                window.scrollTo(0, 0);
            });
        },
        remove_dept(id) {
            var self = this;
            axios.post('removeRoom', {room_id:id}).then(function (response) {
                self.rooms = response.data.rooms; 
                window.scrollTo(0, 0);
            });
        }
       
    }
}
</script>
