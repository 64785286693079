<template>
  <div class="page-body">
    <PageTitle title="Capture Image" />
    <div class="container-fluid">
      <div class="user-profile social-app-profile">
        <div class="row">
          <!-- user profile first-style start-->
          <div class="col-sm-12 box-col-12">
            <div class="card hovercard text-center mb-5"> 
                <video ref="video" autoplay ></video>
               
                  <canvas ref="canvas" style="display: none"></canvas> 
                <div class="user-image">
                  <div class="avatar"><img :src="photo" alt="Captured photo" class="img-100" v-if="photo"></div>
              </div>
            </div>
          </div>
                <button @click="capturePhoto" class="btn btn-primary">Capture Photo</button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.hovercard {
  
  align-items: center;

}
</style>
<script>
import axios from 'axios';
import PageTitle from '@/components/PageTitle.vue'; 
export default {
  components: {
        PageTitle 
    }, 
  data() {
    return {
      photo: null,
    };
  },
  mounted() {
    this.startWebcam();
  },
  methods: {
    async startWebcam() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.$refs.video.srcObject = stream;
      } catch (error) {
        console.error("Error accessing webcam:", error);
      }
    },
    capturePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      this.photo = canvas.toDataURL('image/png');
      this.uploadPhoto();


    },
    async uploadPhoto() {
      try {
        // Convert the data URL to a Blob
        const blob = this.dataURLtoBlob(this.photo);

        // Create a FormData object
        const formData = new FormData();
        formData.append('image', blob, 'captured_photo.png');
        formData.append('token', localStorage['token']);
        var self = this;
        // Send the FormData object to the server using Axios
         axios.post('setVisitorImage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          if(response.data.ok==1){
            if(response.data.name=='')
              self.$router.push({ name: "visitorprofile" }); 
            else
              self.$router.push({ name: "visitorpage" }); 
          }
            
        });

       
      } catch (error) {
        console.error('Upload failed:', error);
      }
    },
    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
  },
};
</script>

<style>
video {
  width: 100%;
  max-width: 250px;
  height: 250px;
}
</style>