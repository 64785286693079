<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Visitor Gatepass" />
        <div class="container-fluid">
            <div class="col-xxl-12">
                <div class="card">
                    <div class="card-header">
                        <button class="btn btn-secondary" type="button" data-bs-toggle="modal"
                            data-original-title="test" data-bs-target="#gatepassAdd">Create Gatepass</button>
                    </div>
                    <div class="card-body">
                        <ul class="nav nav-tabs border-tab border-0 mb-0 nav-danger" id="topline-tab" role="tablist">
                            <li class="nav-item"><a class="nav-link active nav-border pt-0 txt-danger nav-danger"
                                    id="all-gatepass-tab" data-bs-toggle="tab" href="#all-gatepass" role="tab"
                                    aria-controls="all-gatepass" aria-selected="true"><i
                                        class="icofont icofont-man-in-glasses"></i>All</a></li>
                                        <li class="nav-item"><a class="nav-link nav-border txt-danger nav-danger" id="ongoing-tab"
                                    data-bs-toggle="tab" href="#ongoing" role="tab" aria-controls="ongoing"
                                    aria-selected="false"><i class="icofont icofont-file-document"></i>ongoing</a>
                            </li>
                            <li class="nav-item"><a class="nav-link nav-border txt-danger nav-danger" id="requested-tab"
                                    data-bs-toggle="tab" href="#requested" role="tab" aria-controls="requested"
                                    aria-selected="false"><i class="icofont icofont-file-document"></i>Requested</a>
                            </li>
                            <li class="nav-item"><a class="nav-link nav-border txt-danger nav-danger"
                                    id="completed-gatepass-tab" data-bs-toggle="tab" href="#completed-gatepass"
                                    role="tab" aria-controls="completed-gatepass" aria-selected="false"><i
                                        class="icofont icofont-star"></i>Completed</a></li>
                        </ul>
                        <div class="tab-content" id="topline-tabContent">
                            <div class="tab-pane fade show active" id="all-gatepass" role="tabpanel"
                                aria-labelledby="all-gatepass-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">All Gate Pass:</h6>
                                        <button class="btn btn-primary text-white" v-if="reschedule.length > 0"
                                            data-bs-toggle="modal" data-original-title="test"
                                            data-bs-target="#rescheduleHere">Reschedule</button>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display datatable" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Date</th>
                                                        <th>Photo</th>
                                                        <th>Visitor Name</th>
                                                        <th>Firm</th>
                                                        <th>Contact</th>
                                                        <th>Purpose</th>
                                                        <th>Accessories</th>
                                                        <th>Check In</th>
                                                        <th>Check Out</th>
                                                        <th>Check In Status</th>
                                                        <th>Check Out Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in gatepass" :key="item.gatepassid">
                                                        <td><input type="checkbox" @click="() => { selectUsers(item.visitor_id) }"
                                                                :value="item.id" /></td>
                                                        <td>{{ item.schedule_date }}</td>
                                                        <td><a target="_blank" :href="getImageUrl(item.photo)"
                                                                itemprop="contentUrl" data-size="1600x950">
                                                                <img alt="" :src="getImageUrl(item.photo)"
                                                                    v-if="item.photo" class="avatar "
                                                                    itemprop="thumbnail" :alt="item.name">
                                                            </a> </td>
                                                        <td>{{ item.name }} <span v-if="item.designation.length > 0"
                                                                class="badge_sm badge-info">{{
                                                                    item.designation }}</span>
                                                        </td>
                                                        <td>{{ item.firmname }} <span v-if="item.address.length > 0"
                                                                class="badge_sm badge-primary">{{ item.address
                                                                }}</span>
                                                        </td>
                                                        <td>{{ item.contact }}</td>
                                                        <td>{{ item.purpose }} <span v-if="item.meeting_room"
                                                                class="badge_sm badge-primary">{{ item.meeting_room
                                                                }}</span></td>

                                                        <td>{{ item.accessories }}</td>
                                                        <td>
                                                            <div v-if="user.empdept == 3 && item.check_in == null">
                                                                <button title="Mark Check In" class="btn btn-primary"
                                                                    @click="mark_checkin(item.gatepassid, 1)">Check
                                                                    In</button>
                                                            </div>
                                                            <div v-else>
                                                                {{ item.check_in }}
                                                            </div>

                                                        </td>
                                                        <td>
                                                            <div
                                                                v-if="user.empdept == 3 && item.check_out == null && item.check_in != null ">
                                                                <button title="Mark Checkout" class="btn btn-primary"
                                                                    @click="mark_checkin(item.gatepassid, 2)">Check
                                                                    Out</button>
                                                            </div>
                                                            <div v-else>
                                                                {{ item.check_out }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div v-if="item.approval_status == 0"
                                                                class="badge bg-warning">Pending</div>
                                                            <div v-else-if="item.approval_status == 1"
                                                                class="badge bg-success">Accepted</div>
                                                            <div v-else-if="item.approval_status == 2"
                                                                class="badge bg-danger">Denied</div>
                                                        </td>
                                                        <td>
                                                            <div v-if="user.id == item.whom_userid && item.check_in != null && item.checkout_approval == 0">
                                                                <button title="Approve Checkout" class="btn btn-primary"
                                                                    @click="approve_checkout(item.token, 1)">Approve</button>
                                                            </div>
                                                            <div v-else>
                                                                
                                                            <div v-if="item.checkout_approval == 0"
                                                                class="badge bg-warning">Pending</div>
                                                            <div v-else-if="item.checkout_approval == 1"
                                                                class="badge bg-success">Accepted {{ item.checkout_approval_time }}</div>
                                                            <div v-else-if="item.checkout_approval == 2"
                                                                class="badge bg-danger">Denied</div>
                                                            </div>    
                                                        </td>
                                                        <td>
                                                            <ul class="action" v-if="user.empdept != 3">
                                                                <li class="link" v-if="item.approval_status == 1"
                                                                    @click="getQrCode(item.token)"><i
                                                                        class="icofont icofont-qr-code"></i></li>

                                                                <li class="edit" v-if="item.approval_status == 0"
                                                                    @click=""><i class="icon-pencil-alt"></i></li>
                                                                <li title="Room Reservation"
                                                                    @click="meetingroomBook(item)">
                                                                    <i class="icon-home text-primary"></i>
                                                                </li>
                                                                <!--   <li class="link" @click="openlink(item)"><i
                                                                        class="icon-link"></i></li>-->
                                                                <li title="Delete Pass"
                                                                    @click="removeGatepass(item.gatepassid)"><i
                                                                        class="icon-trash text-danger"></i></li>
                                                                <li title="Print Pass"
                                                                    @click="print_pass(item.gatepassid)"><i
                                                                        class="icon-printer"></i></li>
                                                            </ul>
                                                            <ul class="action" v-else>
                                                                <li class="link" v-if="item.approval_status == 1"
                                                                    @click="getQrCode(item.token)"><i
                                                                        class="icofont icofont-qr-code"></i></li>

                                                                <li title="Print Pass"
                                                                    @click="print_pass(item.gatepassid)"><i
                                                                        class="icon-printer"></i></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ongoing" role="tabpanel" aria-labelledby="ongoing-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">Ongoing Gatepass:</h6>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display datatable" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Photo</th>
                                                        <th>Visitor Name</th>
                                                        <th>Firm</th>
                                                        <th>Contact</th>
                                                        <th>Purpose</th>
                                                        <th>Accessories</th>
                                                        <th>Check In</th>
                                                        <th>Check Out Status</th>
                                                        <th>Check Out</th>

                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in ongoing_pass" :key="item.gatepassid">
                                                        <td>{{ item.schedule_date }}</td>
                                                        <td>
                                                            <a target="_blank" :href="getImageUrl(item.photo)"
                                                                itemprop="contentUrl" data-size="1600x950">
                                                                <img alt="" :src="getImageUrl(item.photo)"
                                                                    v-if="item.photo" class="avatar "
                                                                    itemprop="thumbnail" :alt="item.name">
                                                            </a>
                                                        </td>
                                                        <td>{{ item.name }} <span v-if="item.designation.length > 0"
                                                                class="badge_sm  badge-info">{{ item.designation
                                                                }}</span>
                                                        </td>
                                                        <td>{{ item.firmname }} <span v-if="item.address.length > 0"
                                                                class="badge_sm  badge-primary">{{ item.address }}</span>
                                                        </td>
                                                        <td>{{ item.contact }}</td>
                                                        <td>{{ item.purpose }}</td>
                                                        <td>{{ item.accessories }}</td>
                                                        <td>{{ item.check_in }}</td>
                                                        <td>

                                                            <div v-if="item.checkout_approval == 0"
                                                                class="badge bg-warning">Pending</div>
                                                            <div v-else-if="item.checkout_approval == 1"
                                                                class="badge bg-success">Accepted</div>
                                                            <div v-else-if="item.checkout_approval == 2"
                                                                class="badge bg-danger">Denied</div>
                                                                {{ item.checkout_approval_time }}
                                                        </td>
                                                        
                                                        <td>{{ item.check_out }}</td>
                                                        <td>
                                                             
                                                            <ul class="action" >
                                                                <li class="link" v-if="item.approval_status == 1"
                                                                    @click="getQrCode(item.token)"><i
                                                                        class="icofont icofont-qr-code"></i></li>

                                                                <li title="Print Pass"
                                                                    @click="print_pass(item.gatepassid)"><i
                                                                        class="icon-printer text-primary"></i></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="requested" role="tabpanel" aria-labelledby="requested-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">Pending For Approval:</h6>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display datatable" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Photo</th>
                                                        <th>Visitor Name</th>
                                                        <th>Firm</th>
                                                        <th>Contact</th>
                                                        <th>Purpose</th>
                                                        <th>Accessories</th>
                                                        <th>Check In</th>
                                                        <th>Check Out</th>

                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in requested_pass" :key="item.gatepassid">
                                                        <td>{{ item.schedule_date }}</td>
                                                        <td>
                                                            <a target="_blank" :href="getImageUrl(item.photo)"
                                                                itemprop="contentUrl" data-size="1600x950">
                                                                <img alt="" :src="getImageUrl(item.photo)"
                                                                    v-if="item.photo" class="avatar "
                                                                    itemprop="thumbnail" :alt="item.name">
                                                            </a>
                                                        </td>
                                                        <td>{{ item.name }} <span v-if="item.designation.length > 0"
                                                                class="badge_sm  badge-info">{{ item.designation
                                                                }}</span>
                                                        </td>
                                                        <td>{{ item.firmname }} <span v-if="item.address.length > 0"
                                                                class="badge_sm  badge-primary">{{ item.address }}</span>
                                                        </td>
                                                        <td>{{ item.contact }}</td>
                                                        <td>{{ item.purpose }}</td>
                                                        <td>{{ item.accessories }}</td>
                                                        <td>{{ item.check_in }}</td>
                                                        <td>{{ item.check_out }}</td>
                                                        <td>
                                                            <ul class="action">


                                                                <li title="Approve" class="link" @click="accept(item)">
                                                                    <i class="icon-check text-success"></i>
                                                                </li>
                                                                &nbsp;&nbsp;&nbsp;
                                                                <li title="Decline" @click="reject(item)"><i
                                                                        class="icon-close text-danger"></i></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="completed-gatepass" role="tabpanel"
                                aria-labelledby="completed-gatepass-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">Completed Gatepass:</h6>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display  datatable" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Photo</th>
                                                        <th>Visitor Name</th>
                                                        <th>Firm</th>
                                                        <th>Contact</th>
                                                        <th>Purpose</th>
                                                        <th>Accessories</th>
                                                        <th>Check In</th>
                                                        <th>Check Out</th>

                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in completed_pass" :key="item.gatepassid">
                                                        <td>{{ item.schedule_date }}</td>
                                                        <td><a target="_blank" :href="getImageUrl(item.photo)"
                                                                itemprop="contentUrl" data-size="1600x950">
                                                                <img alt="" :src="getImageUrl(item.photo)"
                                                                    v-if="item.photo" class="avatar "
                                                                    itemprop="thumbnail" :alt="item.name">
                                                            </a></td>
                                                        <td>{{ item.name }} <span v-if="item.designation.length > 0"
                                                                class="badge_sm badge-info">{{
                                                                    item.designation }}</span>
                                                        </td>
                                                        <td>{{ item.firmname }} <span v-if="item.address.length > 0"
                                                                class="badge_sm badge-primary">{{ item.address
                                                                }}</span>
                                                        </td>
                                                        <td>{{ item.contact }}</td>
                                                        <td>{{ item.purpose }}</td>
                                                        <td>{{ item.accessories }}</td>
                                                        <td>{{ item.check_in }}</td>
                                                        <td>{{ item.check_out }}</td>

                                                        <td>
                                                            <ul class="action">
                                                                <li class="edit" @click=""><i
                                                                        class="icon-pencil-alt"></i></li>
                                                                <li class="delete" @click="meetingroomBook(item)"><i
                                                                        class="icon-trash"></i></li>
                                                                <li class="link" @click="openlink(item)"><i
                                                                        class="icon-link"></i></li>
                                                                <li class="delete"
                                                                    @click="removeGatepass(item.gatepassid)"><i
                                                                        class="icon-trash"></i></li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer   start-->
    <div class="modal fade" id="rescheduleHere" tabindex="-1" role="dialog" aria-labelledby="rescheduleHere"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gate Pass Generation</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12 position-relative">
                            <label class="form-label">Purpose</label>
                            <input class="form-control" type="text" v-model="reschedule_data.purpose"
                                placeholder="Type Here..." required="">
                        </div>
                        <div class="col-md-6 position-relative">
                            <label class="form-label">Reschedule Date</label>
                            <input class="form-control" type="date" v-model="reschedule_data.date"
                                placeholder="Type Here..." required="">
                        </div> 
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <div class="row">

                            <div class="col-5">
                                <button class="btn btn-sm btn-primary" @click="reschedule_pass">Generate Pass</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="meetingroomBookHere" tabindex="-1" role="dialog" aria-labelledby="meetingroomBookHere"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Reserve Meeting Room</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div>Visitor Name : {{ room_booking_visitor.name }}</div>
                        <div>Visitor Purpose : {{ room_booking_visitor.purpose }}</div>
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-2 col-4 position-relative " v-for="(item, index)  in rooms"
                                    :key="item.id">
                                    <div :class="`${selected_room.roomid == item.id ? 'card bg-danger' : 'card bg-success'}`"
                                        @click="select_room(room_booking_visitor.gatepassid, item.id)">
                                        <div
                                            :class="`${selected_room.roomid == item.id ? 'card-body bg-danger' : 'card-body bg-success'}`">
                                            <label class="form-label">{{ item.roomname }}</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-4">
                                <input class="form-control" type="date" v-model="selected_room.schedule_date">
                            </div>
                            <div class="col-3">
                                <input class="form-control" type="time" v-model="selected_room.schedule_time">
                            </div>
                            <div class="col-5">
                                <button class="btn btn-sm btn-primary" @click="reserve_room">Reserve Meeting
                                    Room</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="qrcodeView" tabindex="-1" role="dialog" aria-labelledby="qrcodeView" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">QR Code</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                            <canvas ref="qrcodeCanvas"></canvas>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalsPart v-if="!['login'].includes($route.name)" @getGatePassData="updateData" />
</template>
<style scoped>
.avatar {
    width: 50px;
    height: 50px;
    border-radius: 30px;
}

.badge_sm {
    padding: 2px 5px;
    border-radius: 10px;
    font-size: smaller;
    color: white;
}
</style>
<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import GatePass from '@/components/GatepassPrint.vue';
import axios from 'axios';
import QRCode from 'qrcode';

export default {
    name: 'CreateGatepass',
    components: {
        PageTitle, ModalsPart, GatePass
    }, mounted() {
        this.getBasics();
        

    }, data() {
        return {
            reschedule: [],
            user: [],
            gatepass: [],
            requested_pass: [],
            completed_pass: [],
            ongoing_pass:[],
            rooms: [],
            room_booking_visitor: [],
            selected_room: { 'roomid': 0, 'gatepassid': 0, 'schedule_date': '', 'schedule_time': '' },
            reschedule_data: { 'date': '', 'purpose': '', 'time': '' }
        }
    },
    methods: {

        select_room(passid, roomid) {
            this.selected_room.roomid = roomid;
            this.selected_room.gatepassid = passid;
        },
        meetingroomBook(item) {
            this.room_booking_visitor = item;
            var today = new Date();
            this.selected_room.schedule_date = today.getFullYear() + '-' + ((today.getMonth() < 10) ? '0' + today.getMonth() : today.getMonth()) + '-' + ((today.getDate() < 10) ? '0' + today.getDate() : today.getDate());//today.getDate();;
            this.selected_room.schedule_time = ((today.getHours() < 10) ? '0' + today.getHours() : today.getHours()) + ":" + ((today.getMinutes() < 10) ? '0' + today.getMinutes() : today.getMinutes());// today.getMinutes() ;


            $("#meetingroomBookHere").modal("show");

        },
        openlink(item) {
            window.open(window.location.origin + "/visitorpage?q=" + item.token, '_blank');
        },
        print_pass(gatepassid) {
            window.open(window.location.origin + "/printpass?q=" + gatepassid, '_blank');
        },
        updateData(value) {
            this.gatepass = value;
        },
        getBasics() {
            var self = this;
            axios.post('getGatepass').then(function (response) {
                self.gatepass = response.data.gatepass;
                self.completed_pass = response.data.completed_pass;
                self.requested_pass = response.data.requested_pass;
                self.ongoing_pass = response.data.ongoing_pass;
                self.rooms = response.data.rooms;
                self.user = response.data.user;
                window.scrollTo(0, 0);
            }).then(() => {
                $(".datatable").DataTable({
                    dom: 'Bfrtip',
                    buttons: ['excel', 'pdf'],
                    "paging": true,
                    "ordering": true,
                    "autoWidth": false
                });
            });
            var today = new Date();
            this.reschedule_data.date = today.getFullYear() + '-' + ((today.getMonth() < 10) ? '0' + today.getMonth() : today.getMonth()) + '-' + ((today.getDate() < 10) ? '0' + today.getDate() : today.getDate());//today.getDate();;
            this.reschedule_data.time = ((today.getHours() < 10) ? '0' + today.getHours() : today.getHours()) + ":" + ((today.getMinutes() < 10) ? '0' + today.getMinutes() : today.getMinutes());// today.getMinutes() ;

        },
        removeGatepass(id) {
            var self = this;
            self.$swal({
                title: "Do You Want To Delete?",
                text: "Are you sure? You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('removeGatepass', { gatepass_id: id }).then(function (response) {
                        self.gatepass = response.data.gatepass;
                        window.scrollTo(0, 0);
                    });
                }
            });

        },
        accept(visit) {
            var self = this;
            self.$swal({
                title: "Do You Want To Accept?",
                text: "Just For Confirmation !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('acceptGatepass', { gatepass_id: visit.gatepassid }).then(function (response) {
                        self.gatepass = response.data.gatepass;
                        self.completed_pass = response.data.completed_pass;
                        self.requested_pass = response.data.requested_pass;
                        self.ongoing_pass = response.data.ongoing_pass;
                        self.rooms = response.data.rooms;
                        self.user = response.data.user;
                        window.scrollTo(0, 0);
                    });
                }
            });

        },
        reject(visit) {
            var self = this;
            self.$swal({
                title: "Do You Want To Decline?",
                text: "Just For Confirmation !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('declineGatepass', { gatepass_id: visit.gatepassid }).then(function (response) {
                        self.gatepass = response.data.gatepass;
                        self.completed_pass = response.data.completed_pass;
                        self.requested_pass = response.data.requested_pass;
                        self.ongoing_pass = response.data.ongoing_pass;
                        self.rooms = response.data.rooms;
                        self.user = response.data.user;
                        window.scrollTo(0, 0);
                    });
                }
            });

        },
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
        mark_checkin(passid, what) {
            var self = this;
            self.$swal({
                title: "Do You Want To Check In?",
                text: "Just For Confirmation !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('checkInUser', { passid: passid, what: what }).then(function (response) {
                        if(self.containsKey(response.data, 'msg')){
                            self.$swal(response.data.msg);
                        }else{
                                self.gatepass = response.data.gatepass;
                                self.completed_pass = response.data.completed_pass;
                                self.requested_pass = response.data.requested_pass;
                                self.ongoing_pass = response.data.ongoing_pass;
                                self.rooms = response.data.rooms;
                                self.user = response.data.user;
                            
                        }

                    });
                }
            });

        },
        reserve_room(visit) {
            var self = this;
            self.$swal({
                title: "Do You Want To Book?",
                text: "Just For Confirmation !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('bookMeetingRoom', { selected_room: this.selected_room }).then(function (response) {
                        if (response.data.is == 1) {
                            $("#meetingroomBookHere").modal("toggle");
                            this.selected_room = { 'roomid': 0, 'gatepassid': 0, 'schedule_date': '', 'schedule_time': '' };
                        }
                        self.$swal(response.data.msg);


                    });
                }
            });

        },
        async getQrCode(token) {
            try {
                if (token.length > 0) {
                    const canvas = this.$refs.qrcodeCanvas;
                    var url = this.$api_host + 'vp?q=' + token;
                    //var url= 'http://192.168.1.8:8081/visitorgatepassview?q='+token;
                    await QRCode.toCanvas(canvas, url, { width: 200 });
                    $("#qrcodeView").modal("toggle");
                }

            } catch (error) {
                console.error(error);
            }
        },
        getImageUrl(image) {
            return `${this.$hostname}/writable/visitors/${image}`;
        },
        selectUsers(id) {
            //in here you can check what ever condition  before append to array.
            if (this.reschedule.includes(id)) {
                this.reschedule.pop(id)
            } else {
                this.reschedule.push(id)
            }
            console.log(this.reschedule);
        },
        reschedule_pass(item) {
            var self=this;
            if(this.reschedule.length>0){
                axios.post('re_createGatePass', { reschedule: this.reschedule,reschedule_data: this.reschedule_data }).then(function (response) {
                        if (response.data.gatepass_success == 1) {
                            $("#rescheduleHere").modal("toggle");
                            self.reschedule_data= { 'date': '', 'purpose': '', 'time': '' };
                        }
                        self.$swal(response.data.gatepass_statusmsg);


                    });
            }
        },
            
            approve_checkout(visitid,what){
            var self = this;
            self.$swal({
                title: "Do You Want To Accept?",
                text: "Just confirmation ?",
                type: "warning",
                showCancelButton: true, 
            }).then((result) => { // <--
                    if(result.value){
                        axios.post('approve_checkout', {gatepass_id:visitid,what:what}).then(function (response) {
                            if(response.data.is==1){
                                self.gatepass = response.data.gatepass;
                                self.completed_pass = response.data.completed_pass;
                                self.requested_pass = response.data.requested_pass;
                                self.ongoing_pass = response.data.ongoing_pass;
                                self.rooms = response.data.rooms;
                                self.user = response.data.user;
                            }
                            
                            self.$swal(response.data.gatepassReq);
                            
                                window.scrollTo(0, 0);
                            }) 
                    } 
                });
          }
          
    }
}
</script>
