import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from '../views/LoginPage.vue'
import UsersView from '../views/master/Users.vue'
import Departments from '../views/master/Departments.vue'
import Meetingrooms from '../views/master/Meetingrooms.vue'
import CreateGatepass from '../views/gatepass/CreateGatepass.vue'
import CreateEmployeeGatepass from '../views/gatepass/CreateEmployeeGatepass.vue'
import EmployeeGatepassView from '../views/gatepass/EmployeeGatepass.vue'
import VisitorPage from '../views/frontuser/VisitorPage.vue'
import Capture from '../views/frontuser/Capture.vue'
import VerifyPage from '../views/gatepass/VerifyPage.vue'
import VehicleRequestView from '../views/vehical/VehicleRequestView.vue'
import register from '../views/frontuser/Register.vue'
import VisitorProfile from '../views/frontuser/VisitorProfile.vue'
import VisitorCreateGatepass from '../views/frontuser/VisitorCreateGatepass.vue'
import ApproveGatepass from '../views/frontuser/ApproveGatepass.vue'
import QrcodeReader from '../views/frontuser/QrcodeReader.vue'
import GatePassView from '../views/frontuser/GatePassView.vue'
import VisitorGatepassView from '../views/frontuser/VisitorGatepassView.vue'
import MeetingRoomBook from '../views/room/MeetingRoomBook.vue'
import CalenderView from '../views/room/CalenderView.vue'
import VehicalRequest from '../views/vehical/VehicalRequest.vue'
import Profile from '../views/users/Profile.vue'
import SecurityDashboard from '../views/security/SecurityDashboard.vue'
import VehicleRequestProcess from '../views/vehical/VehicleRequestProcess.vue'

const routes = [
  { 
    path: '/',
    name: 'register',
    component: register
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/gatepass',
    name: 'gatepass',
    component: CreateGatepass
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: Meetingrooms
  },
  {
    path: '/departments',
    name: 'departments',
    component: Departments
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: HomeView
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView
  },
  {
    path: '/visitorpage',
    name: 'visitorpage',
    component: VisitorPage
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/verify',
    name: 'verify',
    component: VerifyPage
  },
  {
    path: '/capture',
    name: 'capture',
    component: Capture
  },
  {
    path: '/visitorprofile',
    name: 'visitorprofile',
    component: VisitorProfile
  },
  
  {
    path: '/approvegatepass',
    name: 'approvegatepass',
    component: ApproveGatepass
  },
  {
    path: '/qrcodereader',
    name: 'qrcodereader',
    component: QrcodeReader
  },
  {
    path: '/visitorcreategatepass',
    name: 'visitorcreategatepass',
    component: VisitorCreateGatepass
  },{
    path: '/printpass',
    name: 'printpass',
    component: GatePassView
  },{
    path: '/meetingroombook',
    name: 'meetingroombook',
    component: MeetingRoomBook
  },{
    path: '/calender',
    name: 'calender',
    component: CalenderView
  },{
    path: '/createEmployeeGatepass',
    name: 'createEmployeeGatepass',
    component: CreateEmployeeGatepass
  },{
    path: '/employeegatepassview',
    name: 'employeegatepassview',
    component: EmployeeGatepassView
  },
  {
    path: '/visitorgatepassview',
    name: 'visitorgatepassview',
    component: VisitorGatepassView
  }
  ,{
    path: '/vehicalrequest',
    name: 'vehicalrequest',
    component: VehicalRequest
  },
  {
    path: '/vehiclepassview',
    name: 'vehiclepassview',
    component: VehicleRequestView
  }
  ,{
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/securitydashboard',
    name: 'securitydashboard',
    component: SecurityDashboard
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },




  {
    path: '/vp',
    name: 'vp',
    component: VisitorGatepassView
  },
  
  {
    path: '/ap',
    name: 'ap',
    component: ApproveGatepass
  },
  {
    path: '/vr',
    name: 'vr',
    component: VehicleRequestProcess
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
