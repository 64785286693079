<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
      <div class="container-fluid">
            <div class="page-title">
              <div class="row">
                <div class="col-12">
                  <h4>Visitor Profile</h4>
                </div>
              </div>
            </div>
          </div>
        <div class="container-fluid">
            <div class="user-profile social-app-profile">
              <div class="row">
                <!-- user profile first-style start-->
                <div class="col-sm-12 box-col-12">
                  <div class="card hovercard text-center">
                    <div class="cardheader"></div>
                    <div class="user-image">
                      <div @click="capture_img"><div class="avatar"><img alt="" :src="getImageUrl(visitor.photo)"></div>
                      <div class="icon-wrapper"> <i class="icofont icofont-pencil-alt-5"></i></div>
                      </div>
                      <div class="user-designation"></div>
                          <div class="title"> {{visitor.name}} </div>
                          <div class="desc mt-2">{{visitor.firmname}}</div>
                    </div>
                    <div class="info market-tabs ">
                      <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
                        <li class="nav-item"><a class="nav-link active" id="top-timeline" data-bs-toggle="tab" href="#timeline" role="tab" aria-controls="timeline" aria-selected="true">Active</a></li>
                        <li class="nav-item"><a class="nav-link" id="top-photos" data-bs-toggle="tab" href="#photos" role="tab" aria-controls="photos" aria-selected="false">Past</a></li>
                         
                        <li class="nav-item"><a class="nav-link" id="top-about" data-bs-toggle="tab" href="#about" role="tab" aria-controls="about" aria-selected="false">About</a></li> 
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-content" id="top-tabContent">
                <div class="tab-pane fade show active" id="timeline" role="tabpanel" aria-labelledby="timeline">
                  <div class="row">
                   
                    <div class="col-xl-12 col-lg-12 col-md-12  ">
                      <div class="row">
                        <div class="col-sm-12"  v-for="(item ,index)  in visits" :key="item.id">
                          <div class="card">
                            <div class="card-body">
                              <div class="new-users-social mb-0 ">
                                <div class="d-flex "> 
                                  <div class="flex-grow-1"> 
                                    <h6 class="mb-0 f-w-700">{{item.name}} </h6>
                                    <p> Schedule Date : <b>{{item.schedule_date}} </b></p> 
                                  </div>
                                  <span class="pull-right mt-0"> <i data-feather="more-vertical"></i></span>
                                  <span class="badge badge-warning d-flex align-items-center" type="button" v-if="item.approval_status==0">Pending</span>
                                  <span class="badge badge-success d-flex align-items-center" type="button" v-if="item.approval_status==1">Approved</span>
                                  <span class="badge badge-danger d-flex align-items-center" type="button" v-if="item.approval_status==2">Declined</span>
                                </div>
                              </div> 
                              <div class="timeline-content">
                                <span > Purpose : <b>{{item.purpose}}</b></span>
                                <br>
                                <div v-if="item.meeting_room"
                                                                class="badge rounded-pill badge-Info ">Meeting Schedule : {{ item.meeting_room
                                                                }}</div>
                                <div class="social-chat">
                                   
                                  <div class="your-msg"  v-if="item.check_in">
                                    <div class="d-flex">
                                     <i class="fa fa-entry"></i>
                                      <div class="flex-grow-1"> Check In @ {{item.check_in}} </div>
                                    </div>
                                  </div>
                                  
                                   <div class="your-msg" v-if="item.check_out">
                                    <div class="d-flex">
                                     <i class="fa fa-entry"></i>
                                      <div class="flex-grow-1"> Check Out @ {{item.check_out}} </div>
                                    </div>
                                  </div>
                                  
                                   
                                </div>
                                
                              </div>
                            </div>
                            <div class="card-footer" v-if="item.approval_status==1">
                              <button type="button"  @click="addAccessories_modal(item)" class="btn btn-primary btn-xs col-md-3 col-5">Accessories</button>
                              <span  > | </span>
                              <button type="button" @click="getQrCode(item.token)"  class="btn btn-success btn-xs  col-md-3 col-5">QR </button>
                              <span> | </span>
                              <button type="button" v-if="item.check_in!=null && item.check_out==null && item.checkout_approval==0" @click="checkOutRequest(item.token)"  class="btn btn-danger btn-xs  col-md-3 col-10">Request Checkout </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div class="tab-pane fade" id="about" role="tabpanel" aria-labelledby="about">
                  <div class="row">
                    <div class="col-xl-12 xl-40 col-lg-12 col-md-5 box-col-4">
                      <div class="default-according style-1 faq-accordion" id="accordionExample">
                        <div class="card">
                          <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                              <button class="btn btn-link btn-block text-start f-w-600" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">My Profile</button>
                            </h2>
                          </div>
                          <div class="collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="card-body socialprofile filter-cards-view">
                              <div class="d-flex"><img class="img-50 img-fluid m-r-20 rounded-circle" src="../../../assets/images/user/1.jpg" alt="">
                                <div class="flex-grow-1">
                                  <h6 class="font-primary f-w-600">About You</h6>
                                  <span class="d-block"><span><i class="fa fa-phone"> </i><span class="px-2">{{visitor.contact}}</span></span></span>
                                </div>
                              </div>
                              
                              <div class="likes-profile text-center">
                                <h5> <span> <i class="fa fa-heart font-danger"></i> {{visits.length}}</span></h5>
                              </div>
                              <div class="text-center">Times You Visited HD Hyundai</div>
                              <button class="btn btn-danger" @click="logout">Logout</button>
                          </div>
                        </div>
                        </div>
                       
                      </div>
                    </div> 
                   
                  </div>
                </div>
                <div class="tab-pane fade" id="photos" role="tabpanel" aria-labelledby="photos">
                  <div class="row"> 
                    <div class="col-sm-12"  v-for="(item ,index)  in past_visits" :key="item.id">
                          <div class="card">
                            <div class="card-body">
                              <div class="new-users-social mb-0 ">
                                <div class="d-flex "> 
                                  <div class="flex-grow-1"> 
                                    <h6 class="mb-0 f-w-700">{{item.name}} </h6>
                                    <p> Schedule Date : <b>{{item.schedule_date}} </b></p> 
                                  </div>
                                  <span class="pull-right mt-0"> <i data-feather="more-vertical"></i></span>
                                  <span v-if="item.checkout_approval==1" class="badge badge-pill badge-warning text-white">
                                                                    Check Out Approved
                                                            </span>
                                                            <span v-else-if="item.check_out!=null" class="badge badge-pill badge-success text-white">
                                                                    Checked Out
                                                            </span>
                                                            <span v-else-if="item.check_in!=null" class="badge badge-pill badge-info text-white">
                                                                    Checked In
                                                            </span>
                                                            <span v-else-if="item.checkout_approval==2" class="badge badge-pill badge-warning text-white">
                                                                    Check Out Denied
                                                            </span>
                                                            <span v-else-if="item.approval_status==0" class="badge badge-pill badge-warning text-white">
                                                                    Pending
                                                            </span>
                                                            <span v-else-if="item.approval_status==1" class="badge badge-pill badge-success text-white">
                                                                    Accepted
                                                            </span>
                                                            <span v-else-if="item.approval_status==2" class="badge badge-pill badge-danger text-white">
                                                                    Denied
                                                            </span>

                                 
                                </div>
                              </div> 
                              <div class="timeline-content">
                                <span > Purpose : <b>{{item.purpose}}</b></span>
                                <br>
                                <div v-if="item.meeting_room"
                                                                class="badge rounded-pill badge-Info ">Meeting Schedule : {{ item.meeting_room
                                                                }}</div>
                                <div class="social-chat">
                                   
                                  <div class="your-msg"  v-if="item.check_in">
                                    <div class="d-flex">
                                     <i class="fa fa-entry"></i>
                                      <div class="flex-grow-1 checkin"> Check In @ {{item.check_in}} </div>
                                    </div>
                                  </div>
                                  
                                   <div class="your-msg" v-if="item.check_out">
                                    <div class="d-flex">
                                     <i class="fa fa-entry"></i>
                                      <div class="flex-grow-1 checkout"> Check Out @ {{item.check_out}} </div>
                                    </div>
                                  </div>
                                  
                                   
                                </div>
                                
                              </div>
                            </div>
                            
                          </div>
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div class="modal fade" id="qrcodeView" tabindex="-1" role="dialog" aria-labelledby="qrcodeView"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">QR Code</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                          <canvas ref="qrcodeCanvas"></canvas>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="download_pass">Download</button>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="modal fade" id="accessoriesModal" tabindex="-1" role="dialog" aria-labelledby="accessoriesModal"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Mention Carrying Accessories</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                            <div class="input-group">
                              <input class="form-control" type="text" placeholder="Type Accessories Here" aria-label="Type Accessories Here" v-model="accessories_name">
                              <button class="btn btn-outline-warning" @click="add_accessories" type="button">Add</button>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-body"> 
                                <ul class="list-group list-group-flush">
                                  <li  v-for="(item ,index)  in accessories" :key="item.id">{{ item.name }}</li>
                                </ul>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    
                </div>
            </div>
        </div>
    </div>

    </div>  
    <span @click="creategatepass" type="button" class="btn  btn-pill btn-secondary createbtn">Create Gatepass</span>


</template>
<style scoped>
.createbtn{
  position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
}
.page-body{
    margin-left: 0 !important;
    margin-top: 0px!important;
}
.user-profile .hovercard .cardheader{
    height: 100px;
        background: linear-gradient(45deg, #0437c8, #007611);
    }
    @media only screen and (max-width: 575.98px) {
      .tabs-scoial li:nth-child(3) {
          position: sticky;
      }
    }
.checkin{
  background: #d4ffb4;
}
.checkout{
  background: #dbd7ff;
}
</style>

<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue'; 
import axios from 'axios';
import QRCode from 'qrcode';
const config = {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: 'json'
         };
export default {
    name: 'VisitorPage', 
    components: {
        PageTitle 
    }, 
    mounted() {
            if (this.$route.query.q) {
                this.getBasics(this.$route.query.q);
            }else if (localStorage['token']) {
                this.getBasics(localStorage['token'],1);
            }else 
              this.$router.push({ name: "register" });   
        }, 
        data() {
        return {
            visitor: { "name": '', "firmname": '',"contact": '', "purpose": ''},
            visits: [],
            accessories:[],
            addAccessories_passid:'',
            accessories_name:'',
            past_visits:[]
        }
    },
    methods: { 
        //visitorcreategatepass

        creategatepass(){
          window.location.assign(window.location.origin + "/visitorcreategatepass");

        },
        capture_img(){
          window.location.assign(window.location.origin + "/capture");

        },
        updateData(value) {
            this.gatepass = value;
        },
        getBasics(token,isLocalToke=0) {
            var self = this;
            axios.post('getVisitorProfile',{token:token,isLocalToke:isLocalToke}).then(function (response) {
              if(response.data.is==1){
                localStorage.removeItem('_emp_token');
                if(response.data.token.length>5)
                  localStorage.setItem('token', response.data.token);

                self.visitor = response.data.user; 
                self.visits = response.data.visits;  
                self.past_visits = response.data.past_visits;  
                window.scrollTo(0, 0);
              }else if(response.data.is==0){
                localStorage.removeItem('token');
                self.$router.push({ name: "register" });   
              }
              

                
            });
        },
     
       
       
        checkOutRequest(token) {
            var self = this;
            axios.post('checkOutRequest',{token:token}).then(function (response) {
              if(response.data.is==1){ 
                self.$swal(response.data.msg);
                window.scrollTo(0, 0);
              }  });
        },
        add_accessories(token) {
            var self = this;
            axios.post('addGatePasAccessories',{token:localStorage['token'],accessories_passid:this.addAccessories_passid,accessories_name:this.accessories_name}).then(function (response) {
              if(response.data.is==1){ 
                self.accessories = response.data.accessories; 
                window.scrollTo(0, 0);
              }  });
        },
        getImageUrl(image) {
                return `${this.$hostname}/writable/visitors/${image}`;
            },
            
        async getQrCode(token) {
            try {
              if(token.length>0){
                  const canvas = this.$refs.qrcodeCanvas;
                  var url=this.$api_host+'vp?q='+token;
                  //var url= 'http://192.168.1.8:8081/visitorgatepassview?q='+token;
                  await QRCode.toCanvas(canvas,url , { width: 200 });
                  $("#qrcodeView").modal("toggle");
              }
              
            } catch (error) {
              console.error(error);
            }
          }, 
          addAccessories_modal(visit) {
             this.addAccessories_passid=visit.gatepassid;
             if(this.addAccessories_passid>0){
              var self = this;
              axios.post('getAccessories',{token:localStorage['token'],accessories_passid:this.addAccessories_passid,accessories_name:this.accessories_name}).then(function (response) {
                if(response.data.is==1){ 
                    self.accessories = response.data.accessories; 
                    $("#accessoriesModal").modal("toggle");
              }  });
                
                
                 
             }
           
          },
          logout(){
            localStorage.removeItem('token');
            localStorage.removeItem('_emp_token');
            this.$router.push({ name: "register" });  
          }


       
    }
}
</script>
