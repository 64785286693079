import 'simplebar/dist/simplebar.min.css';
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';   

axios.defaults.baseURL = 'https://vms.dktsoft.in/backend/index.php/api';
axios.defaults.headers.common['Authorization'] = (localStorage['token'])?localStorage['token']:'na';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const app = createApp(App);
app.config.globalProperties.$apiname = 'https://vms.dktsoft.in/backend/index.php/api';
app.config.globalProperties.$hostname = 'https://vms.dktsoft.in/backend/';
app.config.globalProperties.$api_host = 'http://vms.dktsoft.in/';
app.config.globalProperties.$cur_host = 'https://vms.dktsoft.in/';
app.config.globalProperties.$router_here =router;
//router.currentRoute.value.
app.use(router);
app.use(VueSweetalert2); 
app.mount('#app');
//createApp(App).use(store).use(router).mount('#app')
