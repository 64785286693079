<template>

    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class="login-card login-dark">
                    <div>
                        <div><a class="logo" href="/">
                                <img class="img-fluid for-dark" src="../../assets/images/logo/logo.png"
                                    alt="looginpage">
                                <img class="img-fluid for-light" src="../../assets/images/logo/logo_dark.png"
                                    alt="looginpage"></a></div>
                        <div class="login-main" >

                            <h4>Employee Login </h4>
                            <p> {{ response }}</p>
                            <div class="form-group">
                                <label class="col-form-label">Employee Code</label>
                                <input class="form-control" type="text" v-model="empcode" placeholder="Type Here..." required="">
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Password</label>
                                <input class="form-control" type="password" v-model="pwd" placeholder="Type Here..."
                                    required="">
                            </div>
                            <div class="text-end mt-3">
                                <button class="btn btn-primary btn-block w-100" @click="verifyEmp"> Login </button>
                            </div>

                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>





</template>
<style scoped>
.page-body {
    margin-left: 0 !important;
    margin-top: 0px;
}
@media only screen and (max-width: 575.98px) {
    .login-card {
        padding-top: 0; 
    }
}
.login-card {
    min-height: 80vh;
}
</style>

<script>
import axios from 'axios';

export default {
    name: 'LoginPage',
    components: {

    },
    mounted() {
        
    },
    data() {
        return {
            empcode: '',
            pwd: '',
            otp: '',
            response:''
        }
    },
    methods: {
         
   
        verifyEmp() {
        var self = this;
        
        if (self.empcode && self.pwd ) {
            axios.post('verifyEmp', { empcode: self.empcode,pwd: self.pwd }).then(function (response) {
                if(response.data.is==1){
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('_emp_token', response.data.token);
                    window.location.href = self.$cur_host+'dashboard';
                }
                else
                self.$swal(response.data.msg); 
                //    self.$router.push({ name: "dashboard" }); 
            });
        }
    } 
}
}
</script>
