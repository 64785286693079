<template>

    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class="login-card login-dark">
                    <div>
                       
                        <div class="login-main"  >

                            <h4>Fill Gatepass Details </h4>
                            
                            <div class="form-group">
                                <label class="col-form-label">Purpose</label>
                                <input class="form-control" type="text" v-model="purpose"
                                    placeholder="Type Here..." required="">
                            </div>
                            
                            <div class="form-group">
                                <label class="col-form-label">Respective Authority</label>
                                <select class="form-select" required="" v-model="empid">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option v-for="(item, index)  in emp" :key="item.id" :value="item.id">{{item.empname }}</option>
                                    </select>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Schedule Date</label>
                                <input class="form-control" type="date" v-model="schedule_date" :min="mindate"
                                placeholder="Type Here..." required="">
                            </div>

                            <div class="text-end mt-3">
                                <button class="btn btn-primary btn-block w-100" @click="saveData">Submit</button>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>





</template>
<style scoped>
 
.page-body {
    margin-left: 0 !important;
    margin-top: 0px;
}

.user-profile .hovercard .cardheader {
    height: 100px;
    background: linear-gradient(45deg, #0437c8, #007611);
}

@media only screen and (max-width: 575.98px) {
    .tabs-scoial li:nth-child(3) {
        position: sticky;
    }
}
</style>

<script>
import axios from 'axios';

export default {
    name: 'VisitorCreateGatepass',
    components: {

    },
    mounted() {
        if(localStorage['token'])
            this.getEmps();
    },
    data() {
        return {
            purpose: '',
            empid:0,
            mindate:this.getCurrentDate(),
            schedule_date:this.getCurrentDate(),
            emp: []

        }
    },
    methods: {  
        getCurrentDate() {
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const dd = String(today.getDate()).padStart(2, '0');

      return `${yyyy}-${mm}-${dd}`;
    },
        getEmps() {
            var self = this;
            axios.post('getEmployeesForPassCreation',{token:localStorage['token']}).then(function (response) {
                
                self.emp = response.data.employees;  
                window.scrollTo(0, 0);
            });
        },
    saveData() {
        var self = this;
        if (self.purpose.length == 0)
        self.$swal("Please Enter Purpose"); 
        else if (self.empid== 0)
        self.$swal("Please Select Person To Meet");
        else
        {
            axios.post('createVisitorGatePass', { schedule_date:self.schedule_date,purpose: self.purpose ,empid: self.empid,token: localStorage['token'] }).then(function (response) {
                if(response.data.gatepass_success==1){ 
                    self.$swal({
                            title: "Successfull",
                            text: ""+response.data.gatepass_statusmsg,
                            type: "warning"
                        }).then((result) => { // <--
                             
                                self.$router.push({ name: "visitorpage" });  
                        });
                        //self.$router.push({ name: "visitorpage" });  
                }

                window.scrollTo(0, 0);
            });
        }
    },

}
}
</script>
