<template>

    <div class="container p-0">
        <div class="row ">
            <div class="col-12 p-0">
                <div class=" m-4 card login-dark">
                    <div   >
                        <div class="card-header"  >
                            <h4>Approve Visitor </h4>
                                                              <span v-if="visit.check_out!=null" class="badge badge-pill badge-success text-white">
                                                                    Checked Out
                                                            </span>
                                                            <span v-else-if="visit.checkout_approval==1" class="badge badge-pill badge-warning text-white">
                                                                    Check Out Approved
                                                            </span>
                                                            <span v-else-if="visit.checkout_approval==2" class="badge badge-pill badge-warning text-white">
                                                                    Check Out Denied
                                                            </span>
                                                            <span v-else-if="visit.check_in!=null" class="badge badge-pill badge-info text-white">
                                                                    Checked In
                                                            </span>
                                                            <span v-else-if="visit.approval_status==0" class="badge badge-pill badge-warning text-white">
                                                                    Pending
                                                            </span>
                                                            <span v-else-if="visit.approval_status==1" class="badge badge-pill badge-success text-white">
                                                                    Accepted
                                                            </span>
                                                            <span v-else-if="visit.approval_status==2" class="badge badge-pill badge-danger text-white">
                                                                    Denied
                                                            </span>
                        </div>
                        <div class="card-body bg-white">
 
                            <div class="col-12 row">
                                <div class="col-4 ">
                                    <div class="avatar"><img alt="" :src="getImageUrl(visit.photo)" v-if="visit.photo"></div>
                                </div>
                                <div class="col-8 ">
                                        <b>Name:</b>  {{ visit.name }}<br>
                                        <b>Phone No.:</b>  {{ visit.contact }}<br>
                                        <b>Firm Name:</b>  {{ visit.firmname }}<br> 
                                        <b>Address:</b>  {{ visit.address }}<br> 
                                </div>
                                 
                            </div> 
                            <div class="form-group">
                                <label class="col-form-label">Purpose : </label>
                                    {{ visit.purpose }}
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Schedule Date : </label>
                                    {{ visit.schedule_date }}
                            </div>
                            
                           
                        </div>
                        <div class="card-footer bg-white"  >
                            <div class="col-12 row" v-if="visit.approval_status!=1 &&  visit.check_in==null && visit.check_out==null">
                                <button class="btn btn-success col-4" @click="accept(visit.token,1)">Approve Check In</button>
                                <div class="col-4"></div>
                                <button class="btn btn-danger  col-4" @click="reject(visit.token,2)">Decline Check In</button>
                            </div>
                            <div class="col-12 row" v-else-if="visit.check_in!=null && visit.check_out==null && visit.checkout_approval==0 ">
                                <button class="btn btn-success col-4" @click="approve_check_out(visit.token,1)">Approve Checkout</button>
                                <div class="col-4"></div>
                                <button class="btn btn-danger  col-4" @click="approve_check_out(visit.token,2)">Decline Checkout </button>
                            </div>
                        </div>

                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>





</template>
<style scoped>
.page-body {
    margin-left: 0 !important;
    margin-top: 0px;
}

.user-profile .hovercard .cardheader {
    height: 100px;
    background: linear-gradient(45deg, #0437c8, #007611);
}

@media only screen and (max-width: 575.98px) {
    .tabs-scoial li:nth-child(3) {
        position: sticky;
    }
}
.avatar img{
    width: 100%; 
}
.login-card {
    min-height: 80vh;
}
</style>

<script>
import axios from 'axios';

export default {
    name: 'ApproveGatepass',
    components: {

    },
    mounted() {
        if (this.$route.query.q) {
                this.getBasics(this.$route.query.q);
            }else
            window.location.href = self.$cur_host+'login';
    },
    data() {
        return {  
            visit: { "name": '', "firmname": '',"contact": '', "purpose": '','schedule_date':'','address':'','photo':'' },
            status:''
        }
    },
    methods: {  
        getBasics(token) {
            var self = this;
            axios.post('getVPApprovalDetails',{token:token}).then(function (response) {
                if(response.data.is==1)
                   self.visit= response.data.gatepassDetails;
                if(response.data.gatepassDetails.approval_status==1)
                    self.status="Request Approved";
                else   if(response.data.gatepassDetails.approval_status==2)
                    self.status="Request Can't Proceed";
               // self.visitor = response.data.user; 
               // self.visits = response.data.visits; 
                window.scrollTo(0, 0);
            });
        },
        accept(visitid,what){
            var self = this;
            self.$swal({
                title: "Do You Want To Accept?",
                text: "Just confirmation ?",
                type: "warning",
                showCancelButton: true, 
            }).then((result) => { // <--
                    if(result.value){
                        axios.post('processGatePass', {gatepass_id:visitid,what:what}).then(function (response) {
                            if(response.data.is==1){
                                self.status=response.data.gatepassReq;
                                self.visit=response.data.visit;
                            }
                            
                            self.$swal(response.data.gatepassReq);
                            
                                window.scrollTo(0, 0);
                            }) 
                    } 
                });
          }, 
          approve_check_out(visitid,what){
            var self = this;
            self.$swal({
                title: "Do You Want To Accept?",
                text: "Just confirmation ?",
                type: "warning",
                showCancelButton: true, 
            }).then((result) => { // <--
                    if(result.value){
                        axios.post('processChechkInOutGatePass', {gatepass_id:visitid,what:what}).then(function (response) {
                            if(response.data.is==1){
                                self.status=response.data.gatepassReq;
                                self.visit= response.data.gatepassDetails;
                            }else
                            self.$swal(response.data.gatepassReq);
                            
                                window.scrollTo(0, 0);
                            }) 
                    } 
                });
          },
        getImageUrl(image) {
                return `${this.$hostname}/writable/visitors/${image}`;
            } 
    

}
}
</script>
