<template>

  <!-- Page Sidebar Ends-->
  <div class="page-body">

    <PageTitle title="Security Dashboard" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row size-column">

        <div class="col-xxl-9 box-col-12">
          <div class="row">
            <div class="col-xl-3 col-sm-6">
              <router-link to="gatepass">
                <div class="card o-hidden small-widget">
                  <div class="card-body total-project border-b-primary border-2"><span
                      class="f-light f-w-500 f-14">Today Schedule</span>
                    <div class="project-details">
                      <div class="project-counter">
                        <h2 class="f-w-600">{{ today_gatepasses.length }}</h2>
                      </div>
                      <div class="product-sub bg-primary-light">

                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-3 col-sm-6">
              <router-link to="gatepass">
                <div class="card o-hidden small-widget">
                  <div class="card-body total-Progress border-b-warning border-2"> <span
                      class="f-light f-w-500 f-14">Today Check-In</span>
                    <div class="project-details">
                      <div class="project-counter">
                        <h2 class="f-w-600">{{ today_gatepasses_active.length }}</h2>
                      </div>
                      <div class="product-sub bg-warning-light">

                      </div>
                    </div>

                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-3 col-sm-6">
              <router-link to="gatepass">
                <div class="card o-hidden small-widget">
                  <div class="card-body total-Complete border-b-secondary border-2"><span
                      class="f-light f-w-500 f-14">Today Check-Out</span>
                    <div class="project-details">
                      <div class="project-counter">
                        <h2 class="f-w-600">{{ today_gatepasses_done.length }}</h2>
                      </div>
                      <div class="product-sub bg-secondary-light">

                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-3 col-sm-6">
              <router-link to="vehicalrequest">
                <div class="card o-hidden small-widget">
                  <div class="card-body total-upcoming"><span class="f-light f-w-500 f-14">Today Vehicle-Req</span>
                    <div class="project-details">
                      <div class="project-counter">
                        <h2 class="f-w-600">{{ today_veh_req.length }}</h2>
                      </div>
                      <div class="product-sub bg-light-light">

                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-xxl-9 box-col-12">
          <div class="row">
            <div class="col-xl-3 col-sm-6">
              <router-link to="gatepass">
                <div class="card o-hidden small-widget">
                  <div class="card-body total-project border-b-primary border-2"><span
                      class="f-light f-w-500 f-14">Create Gatepass</span>
                    
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-3 col-sm-6">
              <router-link to="qrcodereader">
                <div class="card o-hidden small-widget">
                  <div class="card-body total-project border-b-danger border-2"><span
                      class="f-light f-w-500 f-14">Scan Gatepass</span>
                    
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-6 col-sm-6">
               
                <div class="card card-body">
                  <div class="row">
                            <div class="form-group">
                                <label class="col-form-label">Emergency Message</label>
                                <input class="form-control" type="text" v-model="emergency.msg"  placeholder="Type Here..." required="">
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Emergency Option</label>
                                <input class="form-control" type="text" v-model="emergency.option"  placeholder="Type Here..." required="">
                            </div>

                            <div class="text-end mt-3">
                                <button class="btn btn-primary btn-block w-100" @click="saveEmergency">Submit</button>
                            </div>
                          </div>
                </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>



</template>
<style scoped>
.meet_success{
  background: #97ff8d;
  padding: 5px;
}.meet_pending{
  background: #f7f7f7;
  padding: 5px;
}
.accordion-item{
  padding: 5px;
}
  </style>
<script>
import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';

export default {
  name: 'SecurityDashboard',
  components: {
    PageTitle
  },
  mounted() {
    this.getDashboard();
  },
  data() {
    return {
      emp: [name => '', dept => '', empcode => '', role => ''],
      today_gatepasses: [],
      today_gatepasses_active: [],
      today_gatepasses_done: [],
      today_veh_req: [],
      otp: '',
      all_reservations: [],
      emergency:{msg:'',option:''}
    }
  },
  methods: {
    getDashboard() {
      var self = this;

      if (localStorage['token']) {
        axios.post('getDashboard', { token: localStorage['token'] }).then(function (response) {
          if (response.data.is == 1) {
            self.emp = response.data.emp;
            self.today_gatepasses = response.data.today_gatepasses;
            self.today_gatepasses_active = response.data.today_gatepasses_active;
            self.today_gatepasses_done = response.data.today_gatepasses_done;
            self.today_veh_req = response.data.today_veh_req; 
          } else {
          }
        });
      }
    },
        saveEmergency() {
            var self = this;
            self.$swal({
                title: "Do You Want To Send Alert?",
                text: "Just For Confirmation !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('sendEmergency', { emergency: this.emergency }).then(function (response) {
                        if(response.data.status==1)
                          self.emergency={msg:'',option:''};

                        self.$swal(response.data.msg);

                        
                    });
                }
            });

        }
  }
}
</script>
