<template>

    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class="login-card login-dark">
                    <div>
                        <div><a class="logo" href="/">
                                <img class="img-fluid for-dark" src="../../../assets/images/logo/logo.png"
                                    alt="looginpage">
                                <img class="img-fluid for-light" src="../../../assets/images/logo/logo_dark.png"
                                    alt="looginpage"></a></div>
                        <div class="login-main" v-if="otpsent == 0">

                            <h4>Verify Yourself </h4>
                            <p>Enter your Mobile Numer</p>
                            <div class="form-group">
                                <label class="col-form-label">Mobile Numer</label>
                                <input class="form-control" type="text" @input="acceptNumber" v-model="mobileno"
                                    placeholder="Type Here..." required="">
                            </div>

                            <div class="text-end mt-3">
                                <button class="btn btn-primary btn-block w-100" @click="sendOTP">Send OTP</button>
                            </div>

                        </div>
                        <div class="login-main" v-else>

                            <h4>Verify Yourself </h4>
                            <p>Enter OTP</p>
                            <div class="form-group">
                                <label class="col-form-label">OTP Here</label>
                                <input class="form-control" type="number" v-model="otp" placeholder="Type Here..."
                                    required="">
                            </div>

                            <div class="text-end mt-3">
                                <button class="btn btn-primary btn-block w-100" @click="verifyOTP">Verify OTP</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>





</template>
<style scoped>
.page-body {
    margin-left: 0 !important;
    margin-top: 0px;
}

.user-profile .hovercard .cardheader {
    height: 100px;
    background: linear-gradient(45deg, #0437c8, #007611);
}

@media only screen and (max-width: 575.98px) {
    .tabs-scoial li:nth-child(3) {
        position: sticky;
    }
}
</style>

<script>
import axios from 'axios'; 

export default {
    name: 'register',
    components: {
    
    },
    mounted() {
        if(localStorage['token'])
            this.$router.push({ name: "visitorpage" }); 
       // else
         //   this.$router.push({ name: "register" }); 


    },
    data() {
        return {
            mobileno: '',
            otpsent: 0,
            otp: ''
        }
    },
    methods: {
        acceptNumber() {
            var x = this.mobileno.replace(/\D/g, '').match(/(\d{0,10})/);
            this.mobileno = x[1];

        },
   
    sendOTP() {
        var self = this;
        
        if (self.mobileno.length == 10) {
            axios.post('sendVerificationCode', { mobileno: self.mobileno }).then(function (response) {
                if(response.data.is==1)
                    self.otpsent=1;
            });
        }
    },
    verifyOTP() {
        var self = this;
        if (self.mobileno.length == 10) {
            axios.post('verifyCode', { mobileno: self.mobileno ,otp: self.otp }).then(function (response) {
                if(response.data.is==1)
                    localStorage.setItem('token', response.data.token);
                if(localStorage['_emp_token'])
                    localStorage.removeItem('_emp_token');

                    
                    if( response.data.userpic)
                        window.location.assign(window.location.origin + "/visitorpage");
                    else
                        window.location.assign(window.location.origin + "/capture"); 

                window.scrollTo(0, 0);
            });
        }
    },

}
}
</script>
