<template>
    <div class="modal fade" id="departmentAdd" tabindex="-1" role="dialog" aria-labelledby="departmentAdd"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Department</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-12 position-relative">
                                    <label class="form-label">Department Name</label>
                                    <input class="form-control" type="text" v-model="dept.name"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Department Status</label>
                                    <select class="form-select" required="" v-model="dept.status">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option value="1">Active</option>
                                        <option value="2">De-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="save_dept">Add Department</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="employeeAdd" tabindex="-1" role="dialog" aria-labelledby="employeeAdd"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Employee</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Employee Code</label>
                                    <input class="form-control" type="text" v-model="emp.empcode"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Employee Name</label>
                                    <input class="form-control" type="text" v-model="emp.empname"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Employee Contact Number</label>
                                    <input class="form-control" type="text" @input="acceptNumberEmp"  v-model="emp.contact"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Employee Department</label>
                                    <select class="form-select" required="" v-model="emp.empdept">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option v-for="(item, index)  in departments" :key="item.id" :value="item.id">{{
                                            item.deptname }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Employee Password</label>
                                    <input class="form-control" type="text" v-model="emp.emppwd"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Employee Role</label>
                                    <select class="form-select" required="" v-model="emp.emprole">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option value="1">Admin</option>
                                        <option value="2">HOD</option>
                                        <option value="3">Employee</option>
                                        <option value="4">Security Admin</option>
                                    </select>
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-select" required="" v-model="emp.empstatus">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option value="1">Active</option>
                                        <option value="2">De-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="save_emp">Save Data</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="meetingroomAdd" tabindex="-1" role="dialog" aria-labelledby="meetingroomAdd"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Meeting Room</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-12 position-relative">
                                    <label class="form-label">Meeting Room Name</label>
                                    <input class="form-control" type="text" v-model="room.name"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Meeting Room Status</label>
                                    <select class="form-select" required="" v-model="room.status">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option value="1">Active</option>
                                        <option value="2">De-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="save_room">Add Meeting Room</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="gatepassAdd" tabindex="-1" role="dialog" aria-labelledby="gatepassAdd"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Create Visitor Gatepass</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Visitor Name</label>
                                    <input class="form-control" type="text" v-model="visitor.name"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Visitor Firm Name</label>
                                    <input class="form-control" type="text" v-model="visitor.firmname"
                                        placeholder="Type Here..." required="">
                                </div> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Visitor Contact Number</label>
                                    <input class="form-control" type="text" @input="acceptNumber"  v-model="visitor.contact"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Visitor Address</label>
                                    <input class="form-control" type="text" v-model="visitor.address"
                                        placeholder="Type Here..." required="">
                                </div> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Visitor Designation</label>
                                    <input class="form-control" type="text" v-model="visitor.designation"
                                        placeholder="Type Here..." required="">
                                </div> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Visit Purpose</label>
                                    <input class="form-control" type="text" v-model="visitor.purpose"
                                        placeholder="Type Here..." required="">
                                </div> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Schedule Date</label>
                                    <input class="form-control" type="date" v-model="visitor.schedule_date"
                                        placeholder="Type Here..." required="">
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="save_visitor(0)">Save Data</button>
                        <button class="btn btn-primary" @click="save_visitor(1)">Save And Print</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="meetingroomBook" tabindex="-1" role="dialog" aria-labelledby="meetingroomBook"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Reserve Meeting Room</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-3 position-relative "  v-for="(item, index)  in rooms" :key="item.id" >
                                    <div class="card bg-success ">
                                    <div class="card-body bg-success">
                                        <label class="form-label">{{item.roomname}}</label>
                                    </div> 
                                </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 row">
                        <input class="form-control col-4" type="date" v-model="emp.empname" placeholder="Type Here..." required=""> 
                        <div class="col-2"></div>
                        <button class="btn btn-primary  col-6" @click="reserve_room">Reserve Meeting Room</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="EmployeegatepassAdd" tabindex="-1" role="dialog" aria-labelledby="EmployeegatepassAdd"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Create Employee Gatepass</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper">
                        <div class="col-sm-12">
                            <div class="row g-3"> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Select Authority</label>
                                    <select class="form-select" required="" v-model="emp_datepass.hod"> 
                                        <option v-for="(item, index)  in hods" :key="item.id" :value="item.id">{{
                                            item.empname }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Purpose</label>
                                    <input class="form-control" type="text" v-model="emp_datepass.purpose"
                                        placeholder="Type Here..." required="">
                                </div> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Schedule Checkout Time</label>
                                    <input class="form-control" type="time" v-model="emp_datepass.schedule_checkout_time"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Schedule Check In Time</label>
                                    <input class="form-control" type="time" v-model="emp_datepass.schedule_checkin_time"
                                        placeholder="Type Here..." required="">
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="create_pass()">Submit Data</button>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="vehicalReqAdd" tabindex="-1" role="dialog" aria-labelledby="vehicalReqAdd"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Create Vehicle Request</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper">
                        <div class="col-sm-12">
                            <div class="row g-3"> 
                                <div class="col-md-6 d-flex position-relative">
                                    <div class="mb-3 d-flex gap-3 checkbox-checked">
                                            <div class="form-check">
                                            <input class="form-check-input" id="Local" value="Local" type="radio" v-model="vehicalReq.type" >
                                            <label class="form-check-label mb-0" for="Local">Local</label>
                                            </div>
                                            <div class="form-check">
                                            <input class="form-check-input" id="Outstation" type="radio" v-model="vehicalReq.type"  value="Outstation">
                                            <label class="form-check-label mb-0" for="Outstation">Outstation</label>
                                            </div>
                                        </div>
                                    
                                </div> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Select Authority</label>
                                    <select class="form-select" required="" v-model="vehicalReq.hod"> 
                                        <option v-for="(item, index)  in hods" :key="item.id" :value="item.id">{{
                                            item.empname }}</option>
                                    </select>
                                </div>
                                <div class="col-md-12 position-relative">
                                    <label class="form-label">Purpose</label>
                                    <input class="form-control" type="text" v-model="vehicalReq.purpose"
                                        placeholder="Type Here..." required="">
                                </div> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Source Location</label>
                                    <input class="form-control" type="text" v-model="vehicalReq.source"
                                        placeholder="Type Here..." required="">
                                </div> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Destination Location</label>
                                    <input class="form-control" type="text" v-model="vehicalReq.destination"
                                        placeholder="Type Here..." required="">
                                </div> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Schedule Checkout Date</label>
                                    <input class="form-control" type="date" v-model="vehicalReq.schedule_checkout_date"  :min="vehicalReq.schedule_checkout_date"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Schedule Checkout Time</label>
                                    <input class="form-control" type="time" v-model="vehicalReq.schedule_checkout_time" :min="vehicalReq.schedule_checkout_time"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Schedule Check In Date</label>
                                    <input class="form-control" type="date" v-model="vehicalReq.schedule_checkin_date"  :min="vehicalReq.schedule_checkin_date"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Schedule Check In Time</label>
                                    <input class="form-control" type="time" v-model="vehicalReq.schedule_checkin_time"  :min="vehicalReq.schedule_checkin_time"
                                        placeholder="Type Here..." required="">
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="create_vehicle_req()">Submit Data</button>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
export default {
    name: 'ModalsPart',
    emits: ["getDeptData", "getEmpData", "getRoomsData", "getGatePassData","getGateEmpPassData","getVehReqData"],
    mounted() {
        this.getBasics();
            var today = new Date();
            var month=today.getMonth()+1;
            this.emp_datepass.schedule_checkout_time= ((today.getHours()<10)?'0'+today.getHours():today.getHours()) + ":" +((today.getMinutes()<10)?'0'+today.getMinutes():today.getMinutes());// today.getMinutes() ;

            this.vehicalReq.schedule_checkout_time= (((2+today.getHours())<10)?'0'+(2+today.getHours()):(2+today.getHours())) + ":" +((today.getMinutes()<10)?'0'+today.getMinutes():today.getMinutes());// today.getMinutes() ;
            this.vehicalReq.schedule_checkin_time= this.vehicalReq.schedule_checkout_time;//((today.getHours()<10)?'0'+today.getHours():today.getHours()) + ":" +((today.getMinutes()<10)?'0'+today.getMinutes():today.getMinutes());// today.getMinutes() ;

            this.vehicalReq.schedule_checkout_date= today.getFullYear()+'-'+((month<10)?'0'+month:month)+'-'+((today.getDate()<10)?'0'+today.getDate():today.getDate());//today.getDate();;
           this.vehicalReq.schedule_checkin_date= today.getFullYear()+'-'+((month<10)?'0'+month:month)+'-'+((today.getDate()<10)?'0'+today.getDate():today.getDate());//today.getDate();;
           this.vehicalReq.schedule_date= today.getFullYear()+'-'+((month<10)?'0'+month:month)+'-'+((today.getDate()<10)?'0'+today.getDate():today.getDate());//today.getDate();;
           
    },
    data() {
        return {
            emp: { "empname": '', "empcode": '', "empdept": '', "emppwd": '', "emprole": '', "empstatus": '','contact':'' },
            dept: { "name": '', "status": 1 },
            room: { "name": '', "status": 1 },
            visitor: { "name": '', "firmname": '',"contact": '', "address": '',"purpose": '','designation':'','schedule_date':this.todayDate() },
            emp_datepass: { "hod":0,"purpose": '','designation':'','schedule_checkout_time':'','schedule_checkin_time':'' },
            vehicalReq: { "hod":0,"type":'Local',"purpose": '','source':'','destination':'','schedule_checkout_date':'','schedule_checkout_time':'','schedule_checkin_date':'','schedule_checkin_time':'' },
            iscalled: 0,
            departments: [],
            rooms: [],
            gatepass: [],
            myInstance: null,
            hods:[]
        }
    },
    methods: {
        todayDate(){
            var today = new Date();
            var month=today.getMonth()+1;
           
           return today.getFullYear()+'-'+((month<10)?'0'+month:month)+'-'+((today.getDate()<10)?'0'+today.getDate():today.getDate());//today.getDate();;
                    
        },
        acceptNumber() {
        var x = this.visitor.contact.replace(/\D/g, '').match(/(\d{0,10})/);
        this.visitor.contact = x[1];
        if(this.visitor.contact.length==10){
            var self = this;
            axios.post('getVisitor',{contact_no:this.visitor.contact}).then(function (response) {
                if(response.data.is==1)
                    self.visitor=response.data.user;
                window.scrollTo(0, 0);
            });
        }
    },
        acceptNumberEmp() {
        var x = this.emp.contact.replace(/\D/g, '').match(/(\d{0,10})/);
        this.emp.contact = x[1];
         
    },
        getBasics() {
            var self = this;
            axios.post('getDepartments').then(function (response) {
                self.departments = response.data.departments;
                self.rooms = response.data.rooms;
                self.hods = response.data.hods;
                window.scrollTo(0, 0);
            });
        },
        save_visitor() {
            var self = this;
            const formData = new FormData();
            formData.append('name', self.visitor.name);
            formData.append('firmname', self.visitor.firmname);
            formData.append('contact', self.visitor.contact); 
            formData.append('purpose', self.visitor.purpose);
            formData.append('schedule_date', self.visitor.schedule_date); 
            formData.append('address', self.visitor.address); 
            formData.append('designation', self.visitor.designation); 
            axios.post('createGatePass', formData)
                .then(response => {
                    var msg ='';
                    var gatepass_success = response.data.gatepass_success;
                    var visitor_success = response.data.visitor_success;
                    
                        msg =response.data.visitor_statusmsg;
                    if(gatepass_success)
                        msg =response.data.gatepass_statusmsg;
 
                    self.gatepass = response.data.gatepass;
                    if(print==1)
                        window.open(window.location.origin + "/printpass?q=" + response.data.pass_id, '_blank');
                    else{
                        self.$swal(msg); 
                        if (gatepass_success == 1) {
                            $("#gatepassAdd").modal("toggle");
                            self.$emit('getGatePassData', self.gatepass);
                            self.visitor= { "name": '', "firmname": '',"contact": '', "purpose": '','schedule_date':new Date().toLocaleString() };
                        }
                    }
                })
                .catch(error => {
                    console.log('Error on Authentication: ' + error);
                });
        },
        save_room() {
            var self = this;
            const formData = new FormData();
            formData.append('name', self.room.name);
            formData.append('status', self.room.status);
            axios.post('addroom', formData)
                .then(response => {
                    var msg = response.data.msg;
                    var isSuccess = response.data.status;
                    self.rooms = response.data.rooms;
                    self.$swal(msg); 
                    if (isSuccess == 1) {
                        $("#meetingroomAdd").modal("toggle");
                        self.$emit('getRoomsData', self.rooms);
                    }
                })
                .catch(error => {
                    console.log('Error on Authentication: ' + error);
                });
        },
        save_dept() {
            var self = this;
            const formData = new FormData();
            formData.append('deptname', self.dept.name);
            formData.append('deptstatus', self.dept.status);
            axios.post('adddepartment', formData)
                .then(response => {
                    var msg = response.data.msg;
                    var isSuccess = response.data.status;
                    self.departments = response.data.departments;
                    self.$swal(msg);
                    if (isSuccess == 1) {
                        $("#departmentAdd").modal("toggle");
                        self.$emit('getDeptData', self.departments);
                    }
                })
                .catch(error => {
                    console.log('Error on Authentication: ' + error);
                });
        },
        save_emp() {
            var self = this;
            const formData = new FormData();
            formData.append('empname', self.emp.empname);
            formData.append('empcode', self.emp.empcode);
            formData.append('empdept', self.emp.empdept);
            formData.append('emppwd', self.emp.emppwd);
            formData.append('emprole', self.emp.emprole);
            formData.append('empstatus', self.emp.empstatus);
            formData.append('contact', self.emp.contact);
            axios.post('add_employee', formData)
                .then(response => {
                    var msg = response.data.msg;
                    var isSuccess = response.data.status;
                    self.employees = response.data.employees;
                    self.$swal(msg);
                    if (isSuccess == 1) {
                        $("#employeeAdd").modal("toggle");
                        self.$emit('getEmpData', self.employees);
                    }
                })
                .catch(error => {
                    console.log('Error on Authentication: ' + error);
                });
        },
        create_pass() {
                var self = this;
            if(self.emp_datepass.purpose.length==0)
                    self.$swal("Please Enter Gatepass Purpose"); 
            if(self.emp_datepass.hod==0)
                    self.$swal("Please Select HOD"); 
            else if(self.emp_datepass.schedule_checkout_time.length==0)
                    self.$swal("Please Enter Check Out Time"); 
            else{
                const formData = new FormData();
                formData.append('purpose', self.emp_datepass.purpose);
                formData.append('hod', self.emp_datepass.hod);
                formData.append('schedule_checkout_time', self.emp_datepass.schedule_checkout_time);
                formData.append('schedule_checkin_time', self.emp_datepass.schedule_checkin_time);  

                axios.post('createEmpGatePass', formData)
                    .then(response => {
                        var msg ='';
                        var gatepass_success = response.data.gatepass_success; 
                        
                        if(gatepass_success)
                            msg =response.data.gatepass_statusmsg;
    
                        self.gatepass = response.data.gatepass;
                    
                    
                            self.$swal(msg); 
                            if (gatepass_success == 1) {
                                $("#EmployeegatepassAdd").modal("toggle");
                                self.$emit('getGateEmpPassData', self.gatepass);
                                self.emp_datepass= { "purpose": '','designation':'','schedule_checkout_time':'','schedule_checkin_time':'' };
                            }
                        
                    })
                    .catch(error => {
                        console.log('Error on Authentication: ' + error);
                    });
                }
        },
        create_vehicle_req() {
                var self = this;
            if(self.vehicalReq.purpose.length==0)
                    self.$swal("Please Enter Gatepass Purpose"); 
            else if(self.vehicalReq.source.length==0)
                    self.$swal("Please Enter Source Location"); 
            else if(self.vehicalReq.destination.length==0)
                    self.$swal("Please Enter Destination Location"); 
            else if(self.vehicalReq.hod==0)
                    self.$swal("Please Select Authority To Request" ); 
            else{
                const formData = new FormData();
                formData.append('hod_id', self.vehicalReq.hod);
                formData.append('purpose', self.vehicalReq.purpose);
                formData.append('source', self.vehicalReq.source);
                formData.append('req_type', self.vehicalReq.type);
                formData.append('destination', self.vehicalReq.destination);
                formData.append('schedule_checkout_date', self.vehicalReq.schedule_checkout_date);
                formData.append('schedule_checkout_time', self.vehicalReq.schedule_checkout_time);  
                formData.append('schedule_checkin_date', self.vehicalReq.schedule_checkin_date); 
                formData.append('schedule_checkin_time', self.vehicalReq.schedule_checkin_time); 


                axios.post('createVehicalRequest', formData)
                    .then(response => {
                        var msg ='';
                        var req_success = response.data.req_success; 
                        
                        if(req_success)
                            msg =response.data.req_statusmsg;
    
                            if (req_success == 1) {
                                $("#vehicalReqAdd").modal("toggle");
                                self.vehicalReq= { "purpose": '','source':'','destination':'','schedule_checkout_date':'','schedule_checkout_time':'','schedule_checkin_date':'','schedule_checkin_time':'' };
                                self.$emit('getVehReqData', response.data.request); 
                            }
                            self.$swal(msg); 
                        
                    })
                    .catch(error => {
                        console.log('Error on Authentication: ' + error);
                    });
                }
        },
    }
}
</script>
