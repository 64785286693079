<template>
    <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 footer-copyright text-center">
                <p class="mb-0">Copyright 2024 © Application BY DK Techno's  </p>
              </div>
            </div>
          </div>
        </footer>
</template>

<script>


export default {
  name: 'FooterPart',
	mounted() {
		this.sidebar();

	},data() {
  return {
    iscalled:0,
    myInstance: null
  }
},
  onMounted(){ 
  },
    beforeMount() { 
    
      if(!this.isScriptLoaded(this.$cur_host+"assets/js/sidebar-menu.js")){
            const plugin = document.createElement("script");
            plugin.setAttribute("src",this.$cur_host+"assets/js/sidebar-menu.js");
            plugin.async = true;
            document.body.appendChild(plugin);

      }
       if(!this.isScriptLoaded(this.$cur_host+"assets/js/sidebar-pin.js")){
            const plugin2 = document.createElement("script");
            plugin2.setAttribute("src",this.$cur_host+"assets/js/sidebar-pin.js");
            plugin2.async = true;
            document.body.appendChild(plugin2);
            
      }
            if(!this.isScriptLoaded(this.$cur_host+"assets/js/script.js")){
            const plugin4 = document.createElement("script");
            plugin4.setAttribute("src",this.$cur_host+"assets/js/script.js");
            plugin4.async = true;
            document.body.appendChild(plugin4);
            }

            
},
    methods:{
      isScriptLoaded(src)
      {
          return Boolean(document.querySelector('script[src="' + src + '"]'));
      },

    sidebar(){
       
      if(this.iscalled==0){
          const toggle = document.getElementById("searchIcon");
          let newinput = false;
          toggle.addEventListener("click", function(){
              newinput = !newinput
              if(newinput){
              document.getElementById("searchInput").classList.add("show")
              }else{
              document.getElementById("searchInput").classList.remove("show")
              }
          });
          const pinTitle = document.querySelector(".pin-title");
          let pinIcon = document.querySelectorAll(".sidebar-list .fa-thumb-tack");

      }
      this.iscalled=1;
    }
  }
  
}
</script>
 
