<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="My Profile" />
        <div class="container-fluid">
            <div class="edit-profile">
              <div class="row">
                 
                <div class="col-xl-12">
                  <form class="card">
                    <div class="card-header">
                     </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="mb-3">
                            <label class="form-label">Code</label>
                            <input class="form-control" type="text" v-model="employee.empcode" readonly>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-3">
                          <div class="mb-3">
                            <label class="form-label">Name</label>
                            <input class="form-control" type="text" v-model="employee.empname"  readonly>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                          <div class="mb-3">
                            <label class="form-label">Department</label>
                            <input class="form-control" type="text" v-model="employee.dept_name" readonly>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Contact Number</label>
                            <input class="form-control" type="text"  v-model="employee.contact"  readonly>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Password</label>
                            <input class="form-control" type="text"   v-model="employee.emppwd"  readonly>
                          </div>
                        </div> 
                      </div>
                    </div>
                    <div class="card-footer text-end">
                      <button class="btn btn-primary" type="submit">Update Profile</button>
                    </div>
                  </form>
                </div>
                
              </div>
            </div>
          </div>
    </div>
    <!-- footer   start-->

    <ModalsPart v-if="!['login'].includes($route.name)"  @getEmpData="updateEmpData"/>
</template>

<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import axios from 'axios';
const config = {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: 'json'
         };
export default {
    name: 'Profile', 
    components: {
        PageTitle, ModalsPart
    }, mounted() {
        this.getBasics();

    }, data() {
        return {
            employee:{'empcode':'','empname':'','dept_name':'','contact':'','emppwd':''}
        }
    },
    methods: { 
        updateEmpData(value) {
            this.employees = value;
        },
        getBasics() {
            var self = this;
            axios.post('getEmployeeProfile').then(function (response) {
                self.employee = response.data.employee; 
                window.scrollTo(0, 0);
            });
        },
        remove_employee(id) {
            var self = this;
            axios.post('removeEmployee', {EmployeesModel:id}).then(function (response) {
                self.employees = response.data.employees; 
                window.scrollTo(0, 0);
            });
        }
       
    }
}
</script>
