<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Meeting Rooms Calender" />
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            
                        <button class="btn btn-secondary" type="button"  @click="meetingroomBook">Reserve Meeting Room</button>
                     
                        </div>
                        <div class="card-body row">
                            
                                <div class="col-4"  v-for="(item, index)  in all_reservations" :key="item.gatepassid">
                                    <div class="card-header bg-light text-dark heading">
                                        {{item.date}}
                                    </div>  
                                    <div class="card-body border-light">
                                            <div  v-for="(row, index)  in item.schedule" :class="(row.status==2)?'meet_success':''">
                                                <div><b>{{index+1}}. {{row.emp_name}}</b> <span class="badge badge-info">{{row.shedule_time}}</span></div>
                                                <div>Purpose : {{row.purpose}} <span class="badge badge-warning">{{row.room_details}}</span></div>
                                            <hr> 
                                            </div>
                                    </div>      
                                </div>
                                
                            </div>
                             
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="modal fade" id="meetingroomBookHere" tabindex="-1" role="dialog" aria-labelledby="meetingroomBookHere"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Reserve Meeting Room</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                                <div class="col-md-12 position-relative">
                                    <label class="form-label">Meeting Purpose</label>
                                    <input class="form-control" type="text" v-model="selected_room.purpose" placeholder="Type Here..." required="">
                                </div>  
                        <br>
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-2 col-4 position-relative "  v-for="(item, index)  in rooms" :key="item.id" >
                                    <div :class="`${selected_room.roomid==item.id ? 'card bg-danger' : 'card bg-success'}`"  @click="select_room(item.id)">
                                        <div :class="`${selected_room.roomid==item.id ? 'card-body bg-danger' : 'card-body bg-success'}`" >
                                            <label class="form-label">{{item.roomname}}</label>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-4">
                              <input class="form-control" type="date" v-model="selected_room.schedule_date">
                            </div> 
                            <div class="col-3">
                              <input class="form-control" type="time" v-model="selected_room.schedule_time">
                            </div>
                            <div class="col-5">
                                <button class="btn btn-sm btn-primary" @click="reserve_room">Reserve Meeting Room</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalsPart v-if="!['login'].includes($route.name)" @getRoomsData="updateData"/>
</template>
<style scoped>
.border-light{
    border: 1px solid grey;
    padding:5px !important;
}
.border-light div{
    cursor:pointer;
}
.heading{
    padding: 10px !important;
    font-weight: 700;
}
.badge {
    padding: 4px 10px;
}
.col-4{
    margin-top: 5px;
}
.meet_success{
    background: #97ff8d;
    padding: 5px;}
</style>
<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import axios from 'axios';
const config = {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: 'json'
         };
export default {
    name: 'CalenderView', 
    components: {
        PageTitle, ModalsPart
    }, mounted() {
        this.getBasics();

    }, data() {
        return {
            rooms:[], 
            all_reservations:[],
            selected_room:{'roomid':0,'schedule_date':'','schedule_time':'','purpose':''} 
        }
    },
    methods: { 
        select_room(roomid){
            this.selected_room.roomid=roomid; 
        },
        meetingroomBook() {
                    var today = new Date();
                    var month=today.getMonth()+1;
                    this.selected_room.schedule_date = today.getFullYear()+'-'+((month<10)?'0'+(month):month)+'-'+((today.getDate()<10)?'0'+today.getDate():today.getDate());//today.getDate();;
                    this.selected_room.schedule_time  = ((today.getHours()<10)?'0'+today.getHours():today.getHours()) + ":" +((today.getMinutes()<10)?'0'+today.getMinutes():today.getMinutes());// today.getMinutes() ;
    
            $("#meetingroomBookHere").modal("show");

        }, 
        getBasics() {
            var self = this;
            axios.post('getRoomReservationCalender', config).then(function (response) {
                self.rooms = response.data.rooms; 
                self.all_reservations = response.data.all_reservations; 
                window.scrollTo(0, 0);
            });
        }, reserve_room(visit) {
            var self = this;
            self.$swal({
                title: "Do You Want To Book?",
                text: "Just For Confirmation !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('bookEmpMeetingRoom', { selected_room: this.selected_room,from:'calender' }).then(function (response) {
                        if(response.data.is==1){
                            $("#meetingroomBookHere").modal("toggle");        
                                self.rooms = response.data.rooms; 
                                self.all_reservations = response.data.all_reservations; 
                                self.selected_room={'roomid':0,'schedule_date':'','schedule_time':'','purpose':''};
                        }
                        self.$swal(response.data.msg);

                        
                    });
                }
            });

        },
        remove_dept(id) {
            var self = this;
            axios.post('removeRoom', {room_id:id}).then(function (response) {
                self.rooms = response.data.rooms; 
                window.scrollTo(0, 0);
            });
        }
       
    }
}
</script>
