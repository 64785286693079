<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Meetign Room Reservation" />
        <div class="container-fluid">

            <div class="col-xxl-12">
                <div class="card">
                    <div class="card-header">
                        <button class="btn btn-secondary" type="button"  @click="meetingroomBook">Reserve Meeting Room</button>
                    </div>
                    <div class="card-body">
                        <ul class="nav nav-tabs border-tab border-0 mb-0 nav-danger" id="topline-tab" role="tablist">
                            <li class="nav-item"><a class="nav-link active nav-border pt-0 txt-danger nav-danger"
                                    id="all-gatepass-tab" data-bs-toggle="tab" href="#all-gatepass" role="tab"
                                    aria-controls="all-gatepass" aria-selected="true"><i
                                        class="icofont icofont-man-in-glasses"></i>Schedules</a></li>
                            <li class="nav-item"><a class="nav-link nav-border txt-danger nav-danger" id="requested-tab"
                                    data-bs-toggle="tab" href="#requested" role="tab" aria-controls="requested"
                                    aria-selected="false"><i class="icofont icofont-file-document"></i>Completed</a>
                            </li>
                            <li class="nav-item"><a class="nav-link nav-border txt-danger nav-danger" id="Pending-tab"
                                    data-bs-toggle="tab" href="#Pending" role="tab" aria-controls="Pending"
                                    aria-selected="false"><i class="icofont icofont-file-document"></i>Pending</a>
                            </li>
                            <li class="nav-item"><a class="nav-link nav-border txt-danger nav-danger" id="cancelled-tab"
                                    data-bs-toggle="tab" href="#cancelled" role="tab" aria-controls="Pending"
                                    aria-selected="false"><i class="icofont icofont-file-document"></i>Cancelled</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="topline-tabContent">
                            <div class="tab-pane fade show active" id="all-gatepass" role="tabpanel"
                                aria-labelledby="all-gatepass-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">Active Schedules</h6>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>From Time</th>
                                                        <th>To Time</th>
                                                        <th>User</th>
                                                        <th>Room Details</th>
                                                        <th>Purpose</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in active" :key="item.gatepassid">
                                                        <td>{{ item.shedule_date }}</td>
                                                        <td>{{ item.shedule_time }}</td>
                                                        <td>{{ item.upto_time }}</td>
                                                        <td>{{ item.emp_name }}</td> 
                                                        <td>{{ item.room_details }}</td> 
                                                        <td>{{ item.purpose }}</td> 
                                                        <td>
                                                            <ul class="action">
                                                                <li class="edit" @click=""><i
                                                                        class="icon-pencil-alt"></i></li>
                                                                <li class="Mark Completed" 
                                                                    @click="mettingCompleted(item.id)"><i
                                                                        class="icon-check"></i></li>
                                                                <li class="delete"
                                                                    @click="removeReservation(item.id)"><i
                                                                        class="icon-trash"></i></li>
                                                             
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="requested" role="tabpanel" aria-labelledby="requested-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">Completed</h6>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>From Time</th>
                                                        <th>To Time</th>
                                                        <th>User</th>
                                                        <th>Room Details</th> 
                                                        <th>Purpose</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in completed" :key="item.gatepassid">
                                                        <td>{{ item.shedule_date }}</td>
                                                        <td>{{ item.shedule_time }}</td>
                                                        <td>{{ item.upto_time }}</td>
                                                        <td>{{ item.emp_name }}</td> 
                                                        <td>{{ item.room_details }}</td> 
                                                        <td>{{ item.purpose }}</td> 
                                                       
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="Pending" role="tabpanel" aria-labelledby="Pending-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">Pending</h6>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>From Time</th>
                                                        <th>To Time</th>
                                                        <th>User</th>
                                                        <th>Room Details</th> 
                                                        <th>Purpose</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in pending" :key="item.gatepassid">
                                                        <td>{{ item.shedule_date }}</td>
                                                        <td>{{ item.shedule_time }}</td>
                                                        <td>{{ item.upto_time }}</td>
                                                        <td>{{ item.emp_name }}</td> 
                                                        <td>{{ item.room_details }}</td> 
                                                        <td>{{ item.purpose }}</td> 
                                                         <td>
                                                            <ul class="action">
                                                                <li class="edit" @click=""><i
                                                                        class="icon-pencil-alt"></i></li>
                                                                <li class="Mark Completed"
                                                                    @click="mettingCompleted(item.id)"><i
                                                                        class="icon-check"></i></li>
                                                                <li class="delete"
                                                                    @click="removeReservation(item.id)"><i
                                                                        class="icon-trash"></i></li>
                                                             
                                                            </ul>
                                                        </td>
                                                       
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="cancelled" role="tabpanel" aria-labelledby="cancelled-tab">
                                <div class="card-body px-0 pb-0">
                                    <div class="user-header pb-2">
                                        <h6 class="fw-bold">Cancelled</h6>
                                    </div>
                                    <div class="user-content">
                                        <div class="table-responsive custom-scrollbar">
                                            <table class="table display" id="basic-1">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>From Time</th>
                                                        <th>To Time</th>
                                                        <th>User</th>
                                                        <th>Room Details</th> 
                                                        <th>Purpose</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index)  in cancelled" :key="item.gatepassid">
                                                        <td>{{ item.shedule_date }}</td>
                                                        <td>{{ item.shedule_time }}</td>
                                                        <td>{{ item.upto_time }}</td>
                                                        <td>{{ item.emp_name }}</td> 
                                                        <td>{{ item.room_details }}</td> 
                                                        <td>{{ item.purpose }}</td> 
                                                       
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer   start-->
    <div class="modal fade" id="meetingroomBookHere" tabindex="-1" role="dialog" aria-labelledby="meetingroomBookHere"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Reserve Meeting Room</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                                <div class="col-md-12 position-relative">
                                    <label class="form-label">Meeting Purpose</label>
                                    <input class="form-control" type="text" v-model="selected_room.purpose" placeholder="Type Here..." required="">
                                </div>  
                                <div class="row">
                                <div class="col-md-4 col-12">
                                    <label class="form-label">Schedule Date</label>
                                    <input class="form-control" type="date" v-model="selected_room.schedule_date">
                                    </div> 
                                    <div class="col-md-4 col-12">
                                        <label class="form-label">From Time</label>
                                    <input class="form-control" type="time" v-model="selected_room.schedule_time">
                                    </div> 
                                    <div class="col-md-4 col-12">
                                        <label class="form-label">To Time</label>
                                    <input class="form-control" type="time" v-model="selected_room.upto_time">
                                    </div>
                                </div>
                        <br>
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-2 col-4 position-relative "  v-for="(item, index)  in rooms" :key="item.id" >
                                    <div :class="`${selected_room.roomid==item.id ? 'card bg-danger' : 'card bg-success'}`"  @click="select_room(item.id)">
                                        <div :class="`${selected_room.roomid==item.id ? 'card-body bg-danger' : 'card-body bg-success'}`" >
                                            <label class="form-label">{{item.roomname}}</label>
                                        </div> 
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <div class="row">
                            

                            <div class="col-md-5 col-12">
                                <button class="btn btn-sm btn-primary" @click="reserve_room">Reserve Meeting Room</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ModalsPart v-if="!['login'].includes($route.name)" @getGatePassData="updateData" />
</template>
<style scoped>
.avatar {
    width: 50px;
    border-radius: 30px;
}
</style>
<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import GatePass from '@/components/GatepassPrint.vue';
import axios from 'axios';

export default {
    name: 'MeetingRoomBook',
    components: {
        PageTitle, ModalsPart,GatePass
    }, mounted() {
        this.getBasics();

    }, data() {
        return { 
            cancelled: [],
            completed: [],
            active: [],
            rooms:[], 
            pending:[], 
            selected_room:{'roomid':0,'schedule_date':'','schedule_time':'','purpose':'','upto_time':''}
        }
    },
    methods: {
        
        select_room(roomid){
            this.selected_room.roomid=roomid; 
        },
        meetingroomBook() {
                    var today = new Date();
                    var month=today.getMonth()+1;
                    this.selected_room.schedule_date = today.getFullYear()+'-'+((month<10)?'0'+(month):month)+'-'+((today.getDate()<10)?'0'+today.getDate():today.getDate());//today.getDate();;
                    this.selected_room.schedule_time  = ((today.getHours()<10)?'0'+today.getHours():today.getHours()) + ":" +((today.getMinutes()<10)?'0'+today.getMinutes():today.getMinutes());// today.getMinutes() ;
    
            $("#meetingroomBookHere").modal("show");

        }, 
        getBasics() {
            var self = this;
            axios.post('getRoomReservation').then(function (response) { 
                self.active = response.data.active;
                self.completed = response.data.completed;
                self.cancelled = response.data.cancelled;
                self.rooms = response.data.rooms;
                self.pending = response.data.pending;
                window.scrollTo(0, 0);
            });
        },
        removeReservation(id) {
            var self = this;
            self.$swal({
                title: "Do You Want To Delete?",
                text: "Are you sure? You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('removeReservation', { id: id }).then(function (response) {
                                self.active = response.data.active;
                                self.completed = response.data.completed;
                                self.cancelled = response.data.cancelled;
                                self.rooms = response.data.rooms;
                                self.pending = response.data.pending;
                        window.scrollTo(0, 0);
                    });
                }
            });

        },
         
        mettingCompleted(id) {
            var self = this;
            self.$swal({
                title: "Do You Want To Proceed?",
                text: "Confirm that, Your Meeting is Done ?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Done"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('mettingCompleted', { id: id }).then(function (response) {
                                self.active = response.data.active;
                                self.completed = response.data.completed;
                                self.cancelled = response.data.cancelled;
                                self.rooms = response.data.rooms;
                                self.pending = response.data.pending;
                        window.scrollTo(0, 0);
                    });
                }
            });

        },
        reserve_room(visit) {
            var self = this;
            self.$swal({
                title: "Do You Want To Book?",
                text: "Just For Confirmation !",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes"
            }).then((result) => { // <--
                if (result.value) { // <-- if confirmed
                    axios.post('bookEmpMeetingRoom', { selected_room: this.selected_room }).then(function (response) {
                        if(response.data.is==1){
                            $("#meetingroomBookHere").modal("toggle");                 
                                self.active = response.data.active;
                                self.completed = response.data.completed;
                                self.cancelled = response.data.cancelled;
                                self.rooms = response.data.rooms;
                                self.pending = response.data.pending;
                                self.selected_room={'roomid':0,'schedule_date':'','schedule_time':'','purpose':''};
                        }
                        self.$swal(response.data.msg);

                        
                    });
                }
            });

        },
        getImageUrl(image) {
            return `${this.$hostname}/writable/visitors/${image}`;
        }
    }
}
</script>
