<template>

    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class=" login-card  card login-dark">
                    <div  >
                        <div class="card-header"  >
                            <h4>Gatepass Details </h4>
                            <span v-if="gatepass.approval_status==0" class="badge badge-pill badge-warning text-white">
                                                                    Pending
                                                            </span>
                                                            <span v-else-if="gatepass.approval_status==1" class="badge badge-pill badge-success text-white">
                                                                    Accepted
                                                            </span>
                                                            <span v-else-if="gatepass.approval_status==2" class="badge badge-pill badge-danger text-white">
                                                                    Denied
                                                            </span>
                        </div>

                        <div class="card-body bg-white">
 
                            <div class="col-12 row">
                                <div class="col-12 "> 
                                    <canvas ref="qrcodeCanvas"></canvas>
                                </div>
                                <div class="col-12 ">
                                        <b>Code:</b>  {{ visit.empcode }}<br> 
                                        <b>Name:</b>  {{ visit.empname }}<br>
                                        <b>Phone No.:</b>  {{ visit.contact }}<br>
                                        <b>Department:</b>  {{ visit.dept_name }}<br> 
                                </div>
                                 
                            </div> 
                            <div class="form-group">
                                <label class="col-form-label"> <b>Purpose :</b> </label>
                                    {{ visit.purpose }}
                            </div>
                            <div class="form-group">
                                <label class="col-form-label"><b>Check Out Date -Time :</b> </label>
                                   <div> {{ visit.emp_chekout+'-'+visit.schedule_date }}</div>
                            </div> 
                        </div>
                        <div class="card-footer bg-white"  >
                            
                        </div> 
                    </div>
                    
                </div>
            </div>
        </div>
    </div>





</template>
<style scoped>
.page-body {
    margin-left: 0 !important;
    margin-top: 0px;
}

.user-profile .hovercard .cardheader {
    height: 100px;
    background: linear-gradient(45deg, #0437c8, #007611);
}

@media only screen and (max-width: 575.98px) {
    .tabs-scoial li:nth-child(3) {
        position: sticky;
    }
}
.avatar img{
    width: 100%; 
}
.login-card {
    min-height: 80vh;
}
</style>

<script>
import axios from 'axios';
import QRCode from 'qrcode';

export default {
    name: 'EmployeeGatepass',
    components: {

    },
    mounted() {
        if (this.$route.query.q) {
                this.getBasics(this.$route.query.q);
            }else
            window.location.href = this.$cur_host+'login';
    },
    data() {
        return {  
            visit: { "empname": '', "empname": '',"contact": '', "purpose": '','schedule_date':'','address':'','empcode':'','emp_chekout':'', },
            status:''
        }
    },
    methods: {  
        getBasics(token) {
            var self = this;
            axios.post('getEmpGatepassDetails',{token:token}).then(function (response) {
                
                   self.visit= response.data.gatepass;
                   self.getQrCode(self.visit.token);
                window.scrollTo(0, 0);
            });
        },
        async getQrCode(token) {
            try {
              if(token.length>0){
                  const canvas = this.$refs.qrcodeCanvas;
                  var url=this.$api_host+'employeegatepassview?q='+token;
                  await QRCode.toCanvas(canvas,url , { width: 200 });
                  $("#qrcodeView").modal("toggle");
              }
              
            } catch (error) {
              console.error(error);
            }
          },

}
}
</script>
